import React, { useState } from "react";
import { animateScroll as scroll } from 'react-scroll';
import { NavbarWithLinks } from "../../components/Navbar/navbarWithLinks.js";
import Topbar from "../../components/Topbar";
import {
  DefaultContainer,
  DefaultWrapper,
} from "../../components/Topbar/topbarElements";


const styles = {
  form: {
    color: "var(--c4)",
    width: "100%",
    display: "grid",
    gridGap: "20px",
    gridTemplateColumns: "auto auto",
    justifyContent: "center"

  },
  label: {
    width: '280px',
    maxWidth: '35vw'
  },
  doubleCell: {
    gridColumn: "1/3",
  },
  textarea: {
    border: '1px solid var(--c1)',
    borderRadius: '5px',
    height: "30vh",
    width: '600px',
    maxWidth: '80vw',
    outline: 'none'
  },
  input: {
    width: '100%',
    height: '30px',
    border: '1px solid var(--c1)',
    borderRadius: '5px',
    outline: 'none'
  },
  button: {
    width: '100%',
    height: '50px',
    border: '1px solid var(--c1)',
    borderRadius: '5px',
    color: "var(--c4)",
    fontSize: "1.05em",
    fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
    alignSelf: 'end',
  },
  err: {
    fontSize: "0.95em",
    color: 'red'
  }
};

function Contact() {
  scroll.scrollToTop({
    duration: 0,
    delay: 0,
    smooth: 'linear',
  });
  
  const [msg, setMsg] = useState('');
  const [errNameDispay, setErrNameDisplay] = useState('none');
  const [errEmailDispay, setErrEmailDisplay] = useState('none');
  const [errTextDispay, setErrTextDispay] = useState('none');

  let isErr = false;

  const validateName = (name) => {
    if (name.value !== '') {
      setErrNameDisplay('none');
    } else {
      setErrNameDisplay('block');
      isErr = true;
    }
  }

  const validateEmail = (mail) => {
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail.value)) {
      setErrEmailDisplay('none')
    } else {
      setErrEmailDisplay('block');
      isErr = true;
    }
  }

  const validateTextQuestion = (text) => {
    let question = text.value.trim();

    if (question.length >= 10) {
      setErrTextDispay('none')
    } else {
      setErrTextDispay('block');
      isErr = true;
    }
  }

  const onSubmitHandler = (e) => {
    e.preventDefault();
    isErr = false;
    const name = e.target.name;
    const email = e.target.email;
    const text = e.target.text;
    const phone = e.target.phone;
    validateName(name);
    validateEmail(email);
    validateTextQuestion(text);

    if (!isErr) {
      const msgObj = {
        name: name.value,
        phone: phone.value,
        email: email.value,
        request: text.value,
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(msgObj)
      };

      fetch(process.env.REACT_APP_EMAIL_API, requestOptions)
        .then(res => {
          if (res.ok) {
            setMsg("Успешно изпратихте запитване.")
            name.value = '';
            email.value = '';
            text.value = '';
            phone.value = '';
          } else {
            console.log(res)
            setMsg("Възникна грешка. Моля опитайте по-късно.")
            name.value = '';
            email.value = '';
            text.value = '';
            phone.value = '';
          }
        })
        .catch(ex => {
          console.log(ex);
          setMsg("Възникна грешка. Моля опитайте по-късно.")
          name.value = '';
          email.value = '';
          text.value = '';
          phone.value = '';
        });
    }
  }

  return (
    <>
      <NavbarWithLinks disableIcon={true} />
      <Topbar />
      <DefaultContainer>
        <DefaultWrapper>
          <form style={styles.form} onSubmit={onSubmitHandler} >
            <label style={styles.label}>
              Име: <br />
              <input type="text" name="name" style={styles.input} />
              <p style={{ display: errNameDispay, color: "red" }}>Полето е задължително</p>
            </label>
            <label style={styles.label}>
              Email:<br />
              <input type="text" name="email" style={styles.input} />
              <p style={{ display: errEmailDispay, color: "red" }}>Невалиден имейл</p>
            </label>
            <label style={styles.doubleCell}>
              Запитване:<br />
              <textarea style={styles.textarea} name="text" />
              <p style={{ display: errTextDispay, color: "red" }}>Полето трябва да съдържа най-малко 10 символа.</p>
            </label>
            <label style={styles.label}>
              Телефон за връзка:<br />
              <input type="text" name="phone" style={styles.input} />
            </label>
            <input type="submit" value="Изпрати"
              style={{
                cursor: "pointer",
                width: '100%',
                height: '32px',
                color: "var(--c4)",
                fontSize: "1.05em",
                fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
                alignSelf: 'end'
              }} />
            {msg ? <p>{msg}</p> : <></>}
          </form>
        </DefaultWrapper>
      </DefaultContainer>
    </>
  );
}

export default Contact;
