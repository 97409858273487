import React from "react";
import { animateScroll as scroll } from 'react-scroll';
import { NavbarWithLinks } from "../../components/Navbar/navbarWithLinks.js";
import Topbar from "../../components/Topbar";
import {
    DefaultContainer,
    DefaultWrapper,
} from "../../components/Topbar/topbarElements";

const styles = {
    article: {
        color: "var(--c3)",
        fontSize: "1.2em",
        marginBottom: "40px",
    },
    h2: {
        color: "var(--c4)",
        textAlign: "center",
    },
    h3: {
        color: "var(--c4)",
    },
    img: {
        display: "block",
        marginLeft: "20px",
        maxWidth: "calc(100% - 40px)",
    },
    img2: {
        display: "block",
        margin: "2px",
        maxWidth: "calc(80%)",
        width: "300px",
    },
    flex: {
        marginLeft: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
    },
};

function Botox() {
    scroll.scrollToTop({
      duration: 0,
      delay: 0,
      smooth: 'linear',
    });
    return (
        <>
            <NavbarWithLinks disableIcon={true} />
            <Topbar />
            <DefaultContainer>
                <DefaultWrapper>
                    <article style={styles.article}>
                        <h3 style={styles.h3}>Ботокс</h3>
                        <p>Botox (Ботокс) е нарицателно за ботулиновият токсин използван в естетичната медицина.Ботулинов токсин може да започне да се поставя още на 20-25 години с цел превенция на мимическите бръчки- гневните бръчки между веждите, хоризонталните бръчки на челото, бръчките “тип пачи крак” край очите ,бръчките на носа образуващи се при смях„bunny lines”, пушачески бръчки “smoker lines” и корекция на гингивална усмивка. Взависимост от зоната, която ще се третира се определят и единиците, които са необходими за постигане на добър резултат.Например за третиране на гневните бръчки са необходими между 40-50 единици,хоризонтални бръчки на челото около 35-40 единици, бръчки тип“пачи крак“ 10-30 единици на око, бръчки bunny lines около 20 единици, корекция на гингивална усмивка около 10-12 единици. Тези стойности са ориентировъчни и се определят индивидуално. След поставянето на ботулинов токсин има вероятност от главоболие, което е преходно. Поставен в конкретна зона ботулиновият токсин може да се комбинира с филър, като токсина предхожда филъра и е необходимо да има един месец разлика между тях.</p>
                        <p>Преди процедурата с ботулинов токсин трябва да се съобразят няколко основни фактора: </p>
                        <ul>
                            <li>две седмици преди процедурата не е желателно да се използват медикаменти разреждащи кръвта. Всички те водят до намаляване на кръвосъсирването и повишават риска от хематоми.</li>
                            <li>лицето преди процедурата трябва да е чисто без грим. </li>
                            <li>избягвайте алкохола два дни преди процедурата.</li>
                        </ul>
                        <p>Противопоказания:</p>
                        <ul>
                            <li>Бременни и кърмещи </li>
                            <li>При пациенти с нервно-мускулни заболявания като например множествена склероза и миастения гравис или друг тип неврологични заболявания.</li>
                        </ul>
                        <p>Относителни противопоказания:</p>
                        <ul>
                            <li>Пациенти, които приемат някои лекарства, могат да почувстват по-мощен ефект върху мускулите си при инжектиране на токсина. Такива медикаменти са: аминогликозидни антибиотици, пеницилин и неговите производни,хинин и калциевите антагонисти прилагани  в лечението на високо кръвно налягане.</li>
                        </ul>
                        <p>Усложнения:</p>
                        <ul>
                            <li>Възможно е да се обрауват хематоми, които са с преходен характер.</li>
                            <li>Западане на клепача на окото при поставяне на ботокс в областа на гневната бръчка.</li>
                        </ul>
                        <p>След процедурата с ботулинов токсин трябва да знаете:</p>
                        <ul>
                            <li>4 часа след процедурата не  трябва да се навеждате, да лягате и да накланяте главата назад.</li>
                            <li>Лицето да не се третира с козметика поне 24ч.</li>
                            <li>Да се избягват активности следващите 14 дни свързани с обилно потене, например фитнес, също така парна баня, сауна, употребата на алкохол.</li>
                            <li>Третираните зони да не се масажират.</li>
                            <li>Ефекта от ботулиновият токсин започва 1 – 2 седмици след инжектирането и трае 2,5 до 6 месеца.На втората седмица след процедурата посетете Вашия лекар за оценка на ефекта и за корекции, ако се налагат такива.</li>
                        </ul>
                    </article>
                </DefaultWrapper>
            </DefaultContainer>
        </>
    );
}

export default Botox;
