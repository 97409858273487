import styled from 'styled-components';
import {FaTimes} from 'react-icons/fa';
import { Link as LinkScroll } from 'react-scroll';

export const SidebarContainer = styled.aside`
    position:fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    left: 0;
    transition: 0.3s ease-in-out;
    background-color: var(--c2);    
    opacity: ${({ isOpen }) => (isOpen ? '98%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`

export const CloseIcon = styled(FaTimes)`
    color: var(--c3);
`

export const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background:transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`

export const SidebarWrapper = styled.ul`
    padding: 0;
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')}; ;
`

export const SidebarItem = styled.li`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, 60px);
    text-align: center;
`

export const SidebarLink = styled(LinkScroll)`
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    transition: 0.2s ease-in-out;
    font-weight: 700;
    color: var(--c4);
    cursor: pointer;

    &:hover {
        color: var(--c3);
        transition: 0.2s ease-in-out;
    }
`