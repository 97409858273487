import React from "react";
import { animateScroll as scroll } from 'react-scroll';
import { NavbarWithLinks } from "../../components/Navbar/navbarWithLinks.js";
import Topbar from "../../components/Topbar";
import {
  DefaultContainer,
  DefaultWrapper,
} from "../../components/Topbar/topbarElements";

const styles = {
  article: {
    color: "var(--c3)",
    fontSize: "1.2em",
    marginBottom: "40px",
  },
  h2: {
    color: "var(--c4)",
    textAlign: "center",
  },
  h3: {
    color: "var(--c4)",
  },
  img: {
    display: "block",
    marginLeft: "20px",
    maxWidth: "calc(100% - 40px)",
  },
};

function Surgery() {
  scroll.scrollToTop({
    duration: 0,
    delay: 0,
    smooth: 'linear',
  });
  return (
    <>
      <NavbarWithLinks disableIcon={true} />
      <Topbar />
      <DefaultContainer>
        <DefaultWrapper>
          <article style={styles.article}>
            <h2 style={styles.h2}>Хирургия</h2>
            <h3 style={styles.h3}>Вадене на зъб</h3>
            <p> Ваденето на зъби се налага в крайни случай, като например: </p>
            <ul>
              <li>
                Счупване на зъба под нивото на костта следствие от травма{" "}
              </li>
              <li>
                Силно разрушени зъби вследствие на кариозни процеси и
                невъзможност за изграждане, тоест когато няма достатъчно
                количесто зъбна тъкан поне 1мм над нивото на венеца.{" "}
              </li>
              <li>
                Когато зъбът е причина за хронична инфекция и възпалителни
                процеси или е източник на фокална инфекция понякога се налага
                неговото изваждане.{" "}
              </li>
              <li>
                Ортодонтски причини - при несъответствие между размера на
                челюстта и зъбите. В тези случаи има недосиг на място, което не
                може да се компенсира по друг начин.{" "}
              </li>
              <li>
                При наличие на тотален периодонтит (когато има връзка между
                пространството ограждащо корена и устната кухина) - зъбът не
                може да се излекува чрез кореново лечение дори при възможност за
                изграждане.{" "}
              </li>
              <li>При зъби, който се клатят във всички посоки. </li>
            </ul>
            <p>
              {" "}
              Ваденето на зъб/и се извършва под локална упойка. По време на
              процедурата ще усещате натиск, възможно е да се чува пукане, но не
              трябва да се усеща болка. След приключване на процедурата е
              възможно да кърви от раната, което спира до 3-4 мин. На мястото на
              извадения зъб се образува кръвен съсирек, който е естествена
              превръзка на раната и служи като матрица за изграждане на кост.
              Важно е да мине ефекта от упойката и тогава да пристъпите към
              хранене. Студените храни и напитки са за предпочитане. Топлите
              храни и напитки действат съдоразширяващо и усилват кървенето,
              което не е желателно. След преминаване на ефекта на упойката на
              мястото на вадене се усеща „тъпа болка“, което е нормално. По
              преценка на зъболекаря може да се изпише антибиотик. Поддържането
              на оралната хигиена след изваждането на зъб/и е от важно значение
              за да не се развие инфекция. Миенето на зъбите трябва да продължи
              както до момента и колкото е възможно по близо до раната, но не й
              да се бърка в нея с четката за зъби. Задължително условие е
              използването на вода за уста, която също поддържа антисептично
              действие.{" "}
            </p>
            <h3 style={styles.h3}>Много важно е след излизане от кабинета:</h3>
            <ul>
              <li>да не се плюе, а да се преглъща</li>
              <li>не трябва да се измуква от раната</li>
              <li>да се пие със сламка</li>
              <li>да не се жабури усилено</li>
              <li>да не се пуши поне два часа</li>
            </ul>
            <p>
              {" "}
              Поради това, че е възможно при тези процедури да отпадне съсирека
              от раната. При отпадане на кръвния съсирек се развива усложнение
              т.нар. сух алвеолит - съпроводен е със силна постоянна болка,
              пулсираща болка и миризма от раната. Много често това усложнение
              се развива при вадене на зъби в долна челюст, поради две основни
              причини:{" "}
            </p>
            <ul>
              <li>долната челюст е плътна кост и бедно кръвоснабдена</li>
              <li>
                упойката която се използва най-често съдържа адреналин, който
                свива кръвоносните съдове
              </li>
            </ul>
            <p>
              {" "}
              Поради тези факти понякога не може да се образува пълноценен
              съсирек и се развива сух алвеолит. При усложнение след изваждане
              на зъб трябва незабавно да се потърси помощ, тъй като може да се
              стигне до некроза на костта.{" "}
            </p>
          </article>
        </DefaultWrapper>
      </DefaultContainer>
    </>
  );
}

export default Surgery;
