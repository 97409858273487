import React from "react";
import {
  FooterContainer,
  FooterWrapper,
  FooterInfo,
  FooterMapInfo,
  InfoLineBreak,
  InfoHeader,
  InfoLi,
  InfoUl,
  InfoLink,
  InfoA,
  InfoMaps,
  IconWrapper,
  FooterMapsImg,
} from "./footerComponents";
import { HiOutlineMailOpen } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import { BsFacebook, BsQuestionLg } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import mapsImg from "../../images/googleMaps.webp";

const Footer = () => {
  return (
    <>
      <FooterContainer id="contacts">
        <FooterWrapper>
          <FooterInfo>
            <InfoHeader>Свържи се с нас!</InfoHeader>
            <InfoUl>
              <InfoLi>
                <IconWrapper>
                  <FiPhoneCall />
                </IconWrapper>
                <InfoA href="tel:+359895416939">(+359) 895416939</InfoA>
              </InfoLi>
              <InfoLi>
                <IconWrapper>
                  <HiOutlineMailOpen />
                </IconWrapper>
                <InfoA href="mailto:m_vodenicharska@abv.bg">
                  m_vodenicharska@abv.bg
                </InfoA>
              </InfoLi>
            </InfoUl>
          </FooterInfo>
          <FooterInfo>
            <InfoHeader>Последвай ни!</InfoHeader>
            <InfoUl>
              <InfoLi>
                <IconWrapper>
                  <AiFillInstagram/>
                </IconWrapper>
                <InfoA href="https://www.instagram.com/smiledent_vodenicharska/" target="_blank">smiledent_vodenicharska</InfoA>
              </InfoLi>
              <InfoLi>
                <IconWrapper>
                  <BsFacebook />
                </IconWrapper>
                <InfoA href="https://www.facebook.com/%D0%94-%D1%80-%D0%9C%D0%BE%D0%BD%D0%B8%D0%BA%D0%B0-%D0%92%D0%BE%D0%B4%D0%B5%D0%BD%D0%B8%D1%87%D0%B0%D1%80%D1%81%D0%BA%D0%B0-440330359693726" target="_blank">Д-р Моника Воденичарска</InfoA>
              </InfoLi>
            </InfoUl>
          </FooterInfo>
          <FooterInfo>
            <InfoHeader>Информация</InfoHeader>
            <InfoUl>
              <InfoLi>
                <IconWrapper>
                  <BsQuestionLg />
                </IconWrapper>
                <InfoLink to="contact">Попитайте ни <span style={{textDecoration: "underline"}}>тук</span>.</InfoLink>
              </InfoLi>
            </InfoUl>
          </FooterInfo>
          <FooterMapInfo>
            <InfoLineBreak/>
            <InfoHeader>Намери ни!</InfoHeader>
            <InfoUl>
              <InfoLi>
                <InfoMaps href="https://goo.gl/maps/skaWBoHCtgvubxem9" target="_blank">
                    <FooterMapsImg src={mapsImg} alt="Google map of Albany, NY" />
                </InfoMaps>
              </InfoLi>
            </InfoUl>
          </FooterMapInfo>
        </FooterWrapper>
      </FooterContainer>
    </>
  );
};

export default Footer;
