import React from "react";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogo,  
  NavItem,
  MobileIcon,
  NavMenu,
  NavRouteLink,
  LogoTextContainer,
  LogoTextH1,
  NavMenuDisplayBlock,
  LogoTextH3
} from "./NavbarElements";
import logo from "../../images/logo-ex-small.webp";


export const NavbarWithLinks = ({ toggle, disableIcon }) => {
  return disableIcon ? 
     (
      <>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/">
              <img src={logo} height="100%" alt=""/>
              <LogoTextContainer>
                  <LogoTextH1>SMILE DENT</LogoTextH1>
                  <LogoTextH3>Д-р Воденичарска</LogoTextH3>
              </LogoTextContainer>
            </NavLogo>
            <NavMenuDisplayBlock>
              <NavItem>
                <NavRouteLink to='/'>
                  Начало
                </NavRouteLink>
              </NavItem>
            </NavMenuDisplayBlock>
          </NavbarContainer>
        </Nav>
      </>
    )
    : (
      <>
        <Nav>
          <NavbarContainer>
            <NavLogo to="/">
              <img src={logo} height="100%" alt=""/>
              <LogoTextContainer>
                  <LogoTextH1>SMILE DENT</LogoTextH1>
                  <LogoTextH3>Д-р Воденичарска</LogoTextH3>
              </LogoTextContainer>
            </NavLogo>
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
            <NavMenu>
              <NavItem>
                <NavRouteLink to='/'>
                  Начало
                </NavRouteLink>
              </NavItem>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </>
    );
  };