import React from "react";
import { animateScroll as scroll } from 'react-scroll';
import Img1 from "../../images/endodontics1.webp";
import Img2 from "../../images/endodontics2.webp";
import { NavbarWithLinks } from "../../components/Navbar/navbarWithLinks.js";
import Topbar from "../../components/Topbar";
import {
  DefaultContainer,
  DefaultWrapper,
} from "../../components/Topbar/topbarElements";

const styles = {
    article: {
        color: "var(--c3)",
        fontSize: "1.2em",
        marginBottom: "40px"
    },
    h2: {
        color: "var(--c4)",
        textAlign: "center"
    },
    img: {
        display: "block",
        marginLeft: "20px",
        maxWidth: "calc(100% - 40px)"
    }
}

function Endodontics() {
  scroll.scrollToTop({
    duration: 0,
    delay: 0,
    smooth: 'linear',
  });
  return (
    <>
      <NavbarWithLinks disableIcon={true} />
      <Topbar />
      <DefaultContainer>
        <DefaultWrapper>
          <article style={styles.article}>
            <h2 style={styles.h2}>Ендодонтско лечение</h2>
            <p>
              Ендодонтско лечение или така нареченото кореново лечение е
              необходимо в случаите, когато:
            </p>
            <ul>
              <li>
                Kръвоносните съдове и нерви са инфектирани (пулпит) в резултат
                на кариес, травма или отчупване на зъба.
              </li>
              <li>
                Когато инфекцията е достигнала до костта (периодонтит)
                посредством кореновите канали, в които са поместени кръвоносните
                съдове и нерви резултат от нелекуван кариес, травма или
                отчупване от зъба.
              </li>
            </ul>
            <img src={Img1} style={styles.img} alt=""/>
            <p>
              При кореновото лечение се отстраняват кръвоносните съдове и нерви.
              Кореновите канали където са поместени те, внимателно се почистват
              механично с ръчни и/или машинни инструмент, и се дезинфекцират с
              бактерицидни агенти. При необходимост, в зависимост от диагнозата
              и по преценка на зъболекаря, може да се постави медикамент в
              кореновите канали за известно време. След като са почистени, те се
              запълват с инертен материал. Следва изграждането на зъба,
              най-често с фотополимер.
            </p>
            <img src={Img2} style={styles.img} alt=""/>
          </article>
        </DefaultWrapper>
      </DefaultContainer>
    </>
  );
}

export default Endodontics;
