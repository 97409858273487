import React from "react";
import { animateScroll as scroll } from 'react-scroll';
import Img1 from "../../images/procedure1.webp";
import Img2 from "../../images/procedure2.webp";
import Img3 from "../../images/procedure3.webp";
import Img4 from "../../images/procedure4.webp";
import Img5 from "../../images/procedure5.webp";
import Img6 from "../../images/procedure6.webp";
import Img7 from "../../images/procedure7.webp";
import Img8 from "../../images/procedure8.webp";
import Img9 from "../../images/procedure9.webp";
import Img10 from "../../images/procedure10.webp";
import Img11 from "../../images/procedure11.webp";
import { NavbarWithLinks } from "../../components/Navbar/navbarWithLinks.js";
import Topbar from "../../components/Topbar";
import {
    DefaultContainer,
    DefaultWrapper,
} from "../../components/Topbar/topbarElements";

const styles = {
    article: {
        color: "var(--c3)",
        fontSize: "1.2em",
        marginBottom: "40px",
    },
    h2: {
        color: "var(--c4)",
        textAlign: "center",
    },
    h3: {
        color: "var(--c4)",
    },
    img: {
        display: "block",
        marginLeft: "20px",
        maxWidth: "calc(100% - 40px)",
    },
    img2: {
        display: "block",
        margin: "2px",
        maxWidth: "calc(80%)",
        width: "300px",
    },
    flex: {
        marginLeft: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
    },
};

function Aesthetics() {
    scroll.scrollToTop({
      duration: 0,
      delay: 0,
      smooth: 'linear',
    });
    return (
        <>
            <NavbarWithLinks disableIcon={true} />
            <Topbar />
            <DefaultContainer>
                <DefaultWrapper>
                    <article style={styles.article}>
                        <h2 style={styles.h2}>Естетична дентална медицина</h2>
                        <h3 style={styles.h3}>Зъбни фасети</h3>
                        <p>Зъбните фасети представляват тънки люспи, които покриват видимата повърхност на зъбите и са изработени от керамика, фотополимер или цирконий. Чрез тях могат да се коригират различни несъвършенства на естествените зъби. Слагането на зъбни фасети е съвременното решение за подобряване вида на предните зъби, широко прилагано в естетичната стоматология.</p>
                        <p>Предимството на фасетитите е минималното изпиляване и щадене на зъбната тъкан. Изключително тънки от 0,3 - 1мм те могат да покрият дори и много тъмни преоцветявания на зъбите. </p>
                        <p>До поставянето на фасети се стига поради различни причини: </p>
                        <ul>
                            <li>Наличие на петна по зъбите, които не могат да бъдат премахнати чрез избелване или други дентални процедури. </li>
                            <li>Вследствие на фрактури, пломби, провеждано кореново лечение, както и поради генетични фактори, е възможно зъбната редица или отделни зъби да бъдат с неестетична форма и цвят. В този случай слагането на зъбни фасети може да изправи зъбната редица, а видът им да остане максимално естествен. </li>
                            <li>В случаите, когато има разстояния между предните зъби. </li>
                            <li>В някои от случаите с ортодонтски дефекти, като например леко изкривени зъби. </li>
                            <li>В някои от случаите фасетите, освен за естетика, могат да се използват и за лечение на зъби след проведено кореново лечение, подложени на голямо натоварване и с риск от счупване. </li>
                        </ul>
                        <h3 style={styles.h3}>Етапи на лечение:</h3>
                        <ul>
                            <li>Вземане на отпечатък от зъбите на пациена и изпращане в лаборатория. </li>
                            <li>
                                Получаване на восъчния проект на зъбите как ще изглеждат след лечение с фасети. Прехвърляне на проекта на фасетите в устата на пациента. Това става чрез вземане на отпечатък от моделажа на зъбите със силикон. Поставя се платмаса във взетият отпечатък и се поставя в устата на пациента. Така пациентът може да се види как ще изглежда в края на лечението. На този етап може да се коригира формата и големината по желание на пациента. Уточнава се цвета на бъдещите фасети и материала, от който ще се изработят.
                                <img src={Img4} style={styles.img} alt=""/>
                            </li>
                            <li>
                                Изпиляване на зъбите за бъдещите фасети през пренесения проект в устата на пациента.
                                <img src={Img1} style={styles.img} alt=""/>
                            </li>
                            <li>Вземане на отпечатък за бъдещите фасети и изпращане в лаборатория. </li>
                            <li>Изработка на временни фасети по директен метод (на момента) в устата от пластмаса докато станат постоянните. </li>
                            <li>Залепване на временните фасети с временен цимент. </li>
                            <li>В следващото посещение постоянните фасети се пробват в устата на пациента и се пристъпва към тяхното залепване. При необходимост от корекция, фасетите могат да се върнат в лабораторията и да се коригират. </li>
                            <li>
                                Залепване на постоянните фасети.
                                <div style={styles.flex}>
                                    <img src={Img8} style={styles.img2} alt=""/>
                                    <img src={Img9} style={styles.img2} alt=""/>
                                    <img src={Img10} style={styles.img2} alt=""/>
                                    <img src={Img11} style={styles.img2} alt=""/>
                                </div>
                            </li>
                        </ul>
                        <h3 style={styles.h3}>Бондинг</h3>
                        <p> Бондинг е процедура, при която към твърдите зъбни структури се добавя фотополимер. С тази процедура могат да се: </p>
                        <ul>
                            <li> Възтановят отчупени режещи ръбове, пукнатини или други недостатъци причинени или свързани с износване на зъбни тъкани или инциденти. </li>
                            <li> Затворят разстояния между зъбите. </li>
                            <li> Променят формата на зъбите. </li>
                            <li> Променят цвета на зъб или зъби, прикриват тъмни петна с ендогенен характер. </li>
                            <li> Удължат режещите ръбове. </li>
                            <li> Коригират пропорциите, формата и разположението на зъбите за естетвено изглеждащата усмивка. </li>
                            <li> Покрият чувствителни шийки. </li>
                        </ul>
                        <p> Предимствата на този подход на първо място е щаденето на зъбните тъкани, тъй като не се налага допълнително изпиляване. Разбира се ако има предишни, преоцветени пломби или кариес те трябва да бъдат отстранени. Бондингът е първи избор при корекции, които имат по-малка площ. Когато обема на възстановяването е голям или е необходимо да се скрият силно преоцветени зъби, удачен избор са зъбните фасети. Композитните фасети (фотополимерни фасети) представляват цялостно покритие на видимата част на зъба и позволяват уеднаквяване на всички видими зъби. Композитните фасети могат да се изработят със или без изпиляване на зъбите. Когато зъбите не се изпиляват, фотополимерът се наслоява върху тях и те стават по-обемни и окръглени. Налага се изпиляване на зъба когато обема от фотополимер, който ще се сложи върху зъба, няма да е достатъчен за да се постигне желаният ефект. Композитните фасети могат да се изработят по директен (на момента, в едно посещение) или индиректен (в лаборатория) метод.</p>
                        <p> Огромно предимство на съвременните фотополимери е тяхната подобрена здравина, възможността да се полира до блясък, което стабилизира цвета за доста дълго време. </p>
                        <p> Бондингът е бърз, евтин и безболезнен. Извършва се в едно посещение и винаги може да се коригира директно в устата. </p>

                        <div style={styles.flex}>
                            <img src={Img2} style={styles.img2} alt=""/>
                            <img src={Img3} style={styles.img2} alt=""/>
                        </div>

                        <h3 style={styles.h3}>Избелване на зъби</h3>
                        <p>  Оцветяването на зъбите е естествен процес в хода на годините, който силно се ускорява с активния прием на оцветяващи храни и напитки като кафе, кола, тютюнопушене и др. В някои случаи оцветяване на зъбите могат да причинят някои медикаменти. </p>
                        <p>Два са основните метода за избелване на зъбите, които стоматолозите препоръчват – клинично избелване в стоматологичен кабинет и домашно избелване чрез индивидуално изработени шини. Двата метода се базират на един и същ принцип – <b>нанасяне на гел върху зъбите, в който се съдържат избелващи агенти.</b> Основната разлика между двата метода за избелване е <b>концентрацията на избелващите агенти.</b>  При клиничното избелване, концентрацията е много по-голяма и резултатът се постига след само една процедура. При професионалното избелване поради по високата концентрация на избелващия агент се поставя бариера, която да предпазва венеца. Процедурата трае около половин час. Професионалното избелване може да се осъществи с активиране на избелващия агент с ултравиолетова светлина, както и без. Шините за домашно избелване, от друга страна, трябва да се използват в продължение на около 10 дни (по около 4 часа на ден), за да се постигне същия резултат.</p>
                        <img src={Img5} style={styles.img} alt=""/>
                        <br/>
                        <img src={Img6} style={styles.img} alt=""/>
                        <br/>
                        <img src={Img7} style={styles.img} alt=""/>
                        <p> Преди да се започне с избелването зъбите трябва да са подготвени като се премахне зъбния камък и плаката, както и да са полирани, за да се осигури достъп на избелващия агент до зъбната повърхност. Също така като задължително условие е да няма неизлекувани кариеси. Като цяло по-добре се избелват жълтеникавите, отколкото сивкавите зъби. Доколко ще се промени цветът на зъбите зависи до голяма степен от това доколко стриктен е пациентът, спазвайки ограниченията по време на процедурите, но също така и се определя от изходния цвят. Редуцирането на всички цветни храни и напитки в диетата, както и прекратяване на тютюнопушенето са задължителни условия за добрия резултат във времето. При спазване на добра устна хигиена и използване на подходящи продукти за дентална грижа, е възможно ефектът от избелването да се запази в продължение на няколко години. Най-често след 6 месеца до 1 година е добре да се направи единична опреснителна процедура. </p>
                        <p> Понякога е възможна появата на повишена чувствителност към студено след избелване на зъбите. Поради тази причина на пациентите се дава реминерализиращ гел, който използва при необходимост (поне един час дневно). Гела се поставя в същите шини, в който се поставя и избелващия агент. По време на избелващата процедура на пациента се дава и избелваща паста за зъби, като тя се ползва докато свърши. <b>Избелващите гелове действат само върху естествени зъби. Те нямат никакъв ефект върху изкуствени материали, като коронки, фасети и пломби. Ето защо, ако имате пломби или коронки във видимата част на зъбите, ще се наложи да ги подмените, за да уеднаквите цвета.</b> </p>
                        <p> Избелващите пасти за зъби са масово разпространен продукт, който може да се закупи от всеки супермаркет. За съжаление с тях няма как да се постигне така желаната бяла холивудска усмивка. Тези продукти в действителност съдържат избелващи агенти, но концентрацията е далеч по-ниска от това, което е нужно, за да има видим резултат. Избелващите пасти за зъби са добро средство за поддържане на по-траен ефект след клинично или домашно избелване. </p>
                        <p> Постоянно бяла усмивка, която не се променя в годините, може да получите ако на зъбите се поставят фини керамични фасети. Освен че променят цвета на зъбите, те коригират и формата, както и някои малки несъвършенства в подреждането на зъбите.  </p>
                        <p> Всички импровизирани начини за избелване на зъбите в домашни условия могат да бъдат опасни, а и гарантирано имат несравнимо по-лош резултат от професионалното избелване от стоматолог. В по-голямата си част тези методи не само не дават резултат, но и имат абразивно действие и голяма опасност да нарушат целостта на емайла, съответно да се увеличи риска от поява на кариеси и да се повиши чувствителността на зъбите. </p>
                        <p><b>Активният въглен е тайната на белите зъби</b> – въпреки че активният въглен притежава много доказани свойства, избелващото действие не е едно от тях. </p>
                        <p> За да се подложи на клинично избелване е препоръчително пациентът да е над 18 години. Избелването в детска възраст може да увреди тъканите във вътрешността на зъба. Също така, не се препоръчва процедурата да се провежда по време на бременност и кърмене. </p>
                    </article>
                </DefaultWrapper>
            </DefaultContainer>
        </>
    );
}

export default Aesthetics;
