import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Link as LinkScroll } from 'react-scroll';

// margin-top" -80px;
export const Nav = styled.nav`
    background: var(--c1);;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    position: sticky;
    top: 0;
    z-index: 10;

    @media screen and (max-width: 960px) {
        transition: 0.8s all ease;
    }
`
export const NavbarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 0 24px;
    z-index: 1;
    max-width: 1300px;
`
export const LogoTextContainer = styled.div`
    padding-top:32px;
    align-items: center;
    display: flex;
    flex-direction:column;
    @media screen and (max-width: 380px) {
        display: none;
    }
`
export const LogoTextH1 = styled.h1`
    margin:1px;
    font-size: 1.1rem;
    color: var(--c3);
`
export const LogoTextH3 = styled.h3`
    margin:2px;
    font-size: 0.8rem;
    color: var(--c3);
`

export const NavLogo = styled(Link)`
    justify-self: flex-start;
    cursor: pointer;
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    padding-bottom: 15px;
`

export const MobileIcon = styled.div`
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
        position: absolute;
        top: 0px;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 2.5rem;
        cursor: pointer;
        color: var(--c4);
    }
`
export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    @media screen and (max-width: 768px) {
        display: none;        
    }
`
export const NavMenuDisplayBlock = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    padding: 0;
`
export const NavItem = styled.li`
    height: 50px;
    &:hover {
        transform: scale(1.1);
        transition: all 0.2s ease-in-out;
    }
`

export const NavLogoScroll = styled(LinkScroll)`
    justify-self: flex-start;
    cursor: pointer;
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    padding-bottom: 15px;
`
export const NavLink = styled(LinkScroll)`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--c4);

    &.active {
        border-bottom: 3px solid var(--c3);
    }
`

export const NavRouteLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    color: var(--c4);

    &.active {
        border-bottom: 3px solid var(--c3);
    }
`