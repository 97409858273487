import React from "react";
import Img1 from "../../images/caries1.webp";
import Img2 from "../../images/caries2.webp";
import Img3 from "../../images/caries3.webp";
import { animateScroll as scroll } from 'react-scroll';
import { NavbarWithLinks } from "../../components/Navbar/navbarWithLinks.js";
import Topbar from "../../components/Topbar";
import {
  DefaultContainer,
  DefaultWrapper,
} from "../../components/Topbar/topbarElements";

const styles = {
  article: {
    color: "var(--c3)",
    fontSize: "1.2em",
  },
  h2: {
    color: "var(--c4)",
    textAlign: "center",
  },
  h3: {
    color: "var(--c4)",
  },
  img: {
    display: "block",
    marginLeft: "20px",
    maxWidth: "calc(100% - 40px)",
  },
};

function Caries() {
  scroll.scrollToTop({
    duration: 0,
    delay: 0,
    smooth: 'linear',
  });
  return (
    <>
      <NavbarWithLinks disableIcon={true} />
      <Topbar />
      <DefaultContainer>
        <DefaultWrapper>
          <article style={styles.article}>
            <h2 style={styles.h2}>Кариесология</h2>
            <p>
              Кариесът е едно от най-често срещаните заболявания в устната
              кухина. Той представлява увреждане и нарушаване на твърдите зъбни
              тъкани от действието на киселини. Образуват се при разграждането
              на хранителни остатъци от бактериите, които нормално живеят в
              устата на човек. Ако киселините не са от бактериален произход,
              тогава говорим за ерозия на зъбите. Ерозията води до разрушаване
              на зъба слой по слой, докато при кариеса се наблюдава извличане на
              минерал от „вътрешността“ на зъба. Основната причина за развитието
              на кариес е лошата орална хигиена. Освен, че трябва да имате
              правилна техника на миене на зъбите, трябва да го правите и
              достатъчно дълго – поне два пъти дневно по 2-3 минути.
              Препоръчителна е употребата на конци за зъби и/или четки за
              почистване на междузъбните пространства. Колкото и добре да
              почиствате зъбите си сами, винаги е възможно да има места, които
              не достигате, които не виждате или които не знаете как да
              почистите. Зъболекарят е този, който ще се погрижи за тях или ще
              ви обясни как вие да се справяте с дадения проблем. И накрая, но
              не на последно място, не забравяйте редовните посещения при
              зъболекар на всеки 6 месеца. Пропускането на профилактичните
              прегледи категорично може да доведе до образуването на повече
              кариеси.
            </p>
            <h3 style={styles.h3}>Лечение</h3>
            <p>
              {" "}
              Методите за лечение на зъбния кариес зависят от дълбочината на
              кариозния процес. Съществуват три стадия на зъбен кариес:
            </p>
            <ul>
              <li>повърхностен кариес </li>
              <li>среден кариес </li>
              <li>дълбок кариес </li>
            </ul>
            <p>
              {" "}
              Известно е, че ако не се лекува, кариесът може да доведе до
              инфектиране на кръвоносните съдове и нерви (пулпит), след което
              инфекцията може да достигне до костта (периодонтит). В началните
              етапи кариесът се характеризира с болка, предимно от сладко, а при
              напредване на разрушението се развиват различни клинични симптоми
              като:
            </p>
            <ul>
              <li>силна постоянна болка</li>
              <li>пулсираща болка </li>
              <li>нощна болка</li>
              <li>спонтанна болка</li>
            </ul>
            <p>
              {" "}
              При появата на тези симптоми е необходимо незабавно да потърсите
              дентална помощ.{" "}
            </p>
            <img src={Img1} style={styles.img} alt=""/>
            <h3 style={styles.h3}>Етапи на лечение:</h3>
            <p>
              {" "}
              След като се премахне разрушената зъбна тъкан, кухината се попълва
              с висококачествен фотополимер – устойчив на износване и с
              минимален процент на обемно свиване, съответстващ на цветовете и
              сенките на твърдите зъбни тъкани. По преценка на зъболекаря
              взависимост от дълбочината могат да се поставят допълнително
              медикаменти за лечение.
            </p>
            <img src={Img2} style={styles.img} alt=""/>
            <p>
              {" "}
              При големи разрушения на зъба фотополимера е неподходящ като
              материал за възстановяване. Като основен недостатък се посочва
              неговото обемно свиване при втвърдяване на голям обем материал.
              Колкото повече е обема на мариала, толкова повече ще е свиването.
              Това води до отлепване на обтурацията (пломбата) в бъдеще. При
              големи разрушения на зъба, но достатъчно запазени зъбни тъкани,
              препоръчително е поставянето на овърлей. Когато ще се изработва
              овърлей зъбът се оформя по специфичен начин, следва вземане на
              отпечатък, изработването му от зъботехник в лаборатория и
              циментирането му в стоматологичния кабинет. При този тип вставка
              се избягва обемното свиване, тъй като втвърдяването на материала е
              извън устата в специални пещи. Поставя се както върху живи така и
              върху умъртвени зъби. Това е средство на избор само на дъвкателни
              зъби, тъй като възпроизвежда дъвкателната повърхност на зъба.
              Овърлеят най-често се изработва от хибриден фотополимер, керамика
              и цирконий.
            </p>
            <img src={Img3} style={styles.img} alt=""/>
            <p>
              {" "}
              Когато няма достатъчно зъбна тъкан зъбът се изпилява под формата
              на зъбно пънче и се облича в корона, която допълнително го
              предпазва от бъдещо отчупване. По този начин се удължава животът
              на зъба.
            </p>
          </article>
        </DefaultWrapper>
      </DefaultContainer>
    </>
  );
}

export default Caries;
