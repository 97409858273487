import React from "react";
import {
  OverlayRow,
  OverlayColumn,
  OverlayContainer,
  OverlayH1,
  OverlayP,
  OverlayWrapper,
} from "./overlayElements";

const Overlay = () => {
  return (
    <OverlayContainer id="home">
      <OverlayWrapper>
        <OverlayRow>
          <OverlayColumn>
            <OverlayH1>Добре дошли в SmileDent!</OverlayH1>
            <OverlayP>
              Професионални дентални услуги.
            </OverlayP>
          </OverlayColumn>
        </OverlayRow>
      </OverlayWrapper>
    </OverlayContainer>
  );
};

export default Overlay;
