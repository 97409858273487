import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Gallery from "./pages/galery";
import Endodontics from "./pages/endodontics";
import Kids from "./pages/kids";
import Caries from "./pages/caries";
import Orthodontics from "./pages/orthodontics";
import Surgery from "./pages/surgery";
import Contact from "./pages/contact";
import Prosthesis from "./pages/prosthesis";
import Periodontology from "./pages/periodontology";
import Aesthetics from "./pages/aesthetics";
import Botox from "./pages/botox";
import Fillers from "./pages/fillers";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}  />
        <Route path="/galery" element={<Gallery />}  />
        <Route path="/endodontics" element={<Endodontics />}  />
        <Route path="/kids" element={<Kids />}  />
        <Route path="/caries" element={<Caries />}  />
        <Route path="/orthodontics" element={<Orthodontics />}  />
        <Route path="/surgery" element={<Surgery />}  />
        <Route path="/contact" element={<Contact />}  />
        <Route path="/prosthesis" element={<Prosthesis />}  />
        <Route path="/periodontology" element={<Periodontology />}  />
        <Route path="/aesthetics" element={<Aesthetics />}  />
        <Route path="/botox" element={<Botox />}  />
        <Route path="/fillers" element={<Fillers />}  />
      </Routes>
    </Router>
  );
}

export default App;
