import React, { useState } from "react";
import InfoSection from "../../components/Info";
import { teamObject, galeryObject } from "../../components/Info/data";
import { animateScroll as scroll } from 'react-scroll';
import Navbar from "../../components/Navbar";
import Overlay from "../../components/Overlay";
import Services from "../../components/Services";
import Sidebar from "../../components/Sidebar";
import Topbar from "../../components/Topbar";
import Footer from "../../components/Footer";

function Home() {
  scroll.scrollToTop({
    duration: 0,
    delay: 0,
    smooth: 'linear',
  });
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Topbar />
      <Overlay />
      <InfoSection {...teamObject}/>
      <InfoSection {...galeryObject}/>
      <Services />
      <Footer />
    </>
  );
}

export default Home;
