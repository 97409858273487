import { useState } from "react";
import { animateScroll as scroll } from 'react-scroll';
import Topbar from "../../components/Topbar";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { NavbarWithLinks } from "../../components/Navbar/navbarWithLinks.js";
import {
  DefaultContainer,
  DefaultWrapper,
} from "../../components/Topbar/topbarElements";

import Img1 from "../../images/IMG_0110.webp";
import Img2 from "../../images/IMG_0116.webp";
import Img3 from "../../images/IMG_0119.webp";
import Img4 from "../../images/IMG_0122.webp";
import Img5 from "../../images/IMG_0128.webp";
import Img6 from "../../images/IMG_0143.webp";
import Img7 from "../../images/IMG_0179.webp";
import Img8 from "../../images/IMG_0207.webp";
import Img9 from "../../images/IMG_0208.webp";
import Img10 from "../../images/IMG_0211.webp";
import Img11 from "../../images/IMG_0218.webp";
import Img12 from "../../images/IMG_0225.webp";
import Img13 from "../../images/IMG_0232.webp";


const photos = [
  {
    src: Img8,
    width: 2000,
    height: 1370,
  },
  {
    src: Img7,
    width: 2000,
    height: 1370,
  },
  {
    src: Img3,
    width: 2000,
    height: 1370,
  },
  {
    src: Img4,
    width: 2000,
    height: 1370,
  },
  {
    src: Img10,
    width: 2000,
    height: 1370,
  },
  {
    src: Img5,
    width: 2000,
    height: 1370,
  },
  {
    src: Img1,
    width: 2000,
    height: 1370,
  },
  {
    src: Img2,
    width: 2000,
    height: 1370,
  },
  {
    src: Img6,
    width: 2000,
    height: 1370,
  },
  {
    src: Img9,
    width: 2000,
    height: 1370,
  },
  {
    src: Img11,
    width: 2000,
    height: 1370,
  },
  {
    src: Img12,
    width: 2000,
    height: 1370,
  },
  {
    src: Img13,
    width: 2000,
    height: 1370,
  },
];

const slides = photos.map(({ src, width, height }) => ({
  src,
  width,
  height,
}));

const styles = {
  imageGallery: {
    justifyContent: "center",
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    padding: "0"
  },
  li: {
    height: "250px",
    cursor: "pointer",
    position: "relative",
    listStyleType: "none"
  },
  img: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    verticalVlign: "middle",
    borderRadius: "5px"
  }
};


function Gallery() {
  scroll.scrollToTop({
    duration: 0,
    delay: 0,
    smooth: 'linear',
  });
  const [index, setIndex] = useState(-1);

  return (
    <>
      <NavbarWithLinks disableIcon={true} />
      <Topbar />
      <DefaultContainer>
        <DefaultWrapper>
          <ul style={styles.imageGallery}>
            {
              photos.map((x, i) => {
                return <li key={x.src} style={styles.li} onClick={()=> setIndex(i)}>
                  <img src={x.src} alt="smiledent pic" style={styles.img} />
                </li>
              })}
          </ul>

        </DefaultWrapper>
      </DefaultContainer>

      <Lightbox
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
      />
    </>
  );
}

export default Gallery;
