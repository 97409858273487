import styled from "styled-components";
import { Link } from "react-router-dom";

export const ServicesContainer = styled.div`
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--main-grey);
  margin-bottom: 50px;
`;

export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: var(--c4);
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;
export const ServicesWrapper = styled.div`
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;
export const ServicesCard = styled(Link)`
text-decoration: none;
  margin: 5px;
  width: 240px;
  height: 250px;
  padding: 0;
  background: var(--c1);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  box-sizing: border-box;
  overflow: hidden;
  transition: 0.2s ease all;
  cursor: pointer;

  &:hover img {
    margin-top: -80px;
  }
  &:focus-within img {
    margin-top: -80px;
  }
  &:hover h2 {
    padding: 8px 12px 0;
  }
  &:focus-within h2 {
    padding: 8px 12px 0;
  }
`;

export const ServicesIcon = styled.img`
  margin: 0;
  width: 100%;
  object-fit: cover;
  display: block;
  transition: 0.2s ease all;
`;
export const ServicesH2 = styled.h2`
  margin: 0;
  padding: 12px 12px 108px;
  line-height: 32px;
  font-weight: 500;
  font-size: 18px;
  transition: 0.2s ease all;
  font-weight: bold;
  color: var(--c3);
`;

export const ServicesInfoWrapper = styled.div`
  display: block;
  padding: 8px 12px;
  transition: 0.2s ease all;
`;
export const ServicesP = styled.p`
  margin: 0;
  line-height: 1.5;
  transition: 0.2s ease all;
  color: var(--c4);
`;
