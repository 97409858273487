import React, { useState } from "react";
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  InfoColumn1,
  InfoColumn2,
  Heading,
  Subtitle,
  TextWrapper,
  TopLine,
  BtnWrapper,
  Button,
  Img,
  ImgWrap,
  ArrowForward,
  ArrowRight,
} from "./infoElements.js";

const InfoSection = ({
  id,
  primary,
  lightBg,
  lightText,
  lightTextDesc,
  buttonDarkText,
  showButton,
  buttonBig,
  buttonFontBig,
  topLine,
  headLine,
  description,
  bottonLabel,
  imgStart,
  img,
  alt,
}) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id} >
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <InfoColumn1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headLine}</Heading>
                <Subtitle lightText={lightTextDesc}>{description}</Subtitle>
                {
                showButton ? 
                  <BtnWrapper>
                    <Button
                      onMouseEnter={onHover}
                      onMouseLeave={onHover}
                      to="galery"
                      light={buttonDarkText ? 1 : 0}
                    > 
                      {bottonLabel} {hover ? <ArrowForward /> : <ArrowRight />}
                    </Button>
                  </BtnWrapper>
                : <></>
                }
              </TextWrapper>
            </InfoColumn1>
            <InfoColumn2>
              <ImgWrap>
                <Img alt={alt} src={img} />
              </ImgWrap>
            </InfoColumn2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
