import React from "react";
import { GoLocation } from "react-icons/go";
import { FiPhoneCall } from "react-icons/fi";
import { TopbarContainer, TopbarWrapper, TopbarText, TopbarAHref, IconWrapper } from "./topbarElements";

const Topbar = () => {
  return (
    <TopbarContainer id="topbar">
      <TopbarWrapper>
        <TopbarText>
          <IconWrapper>
            <GoLocation />
          </IconWrapper>
          <TopbarAHref href="https://goo.gl/maps/skaWBoHCtgvubxem9" target="_blank">гр. София, ж.к. Банишора, ул. Карлово 7</TopbarAHref>
        </TopbarText>
        <TopbarText>
          <IconWrapper>
            <FiPhoneCall />
          </IconWrapper>
          <TopbarAHref href="tel:+359895416939">(+359) 895416939</TopbarAHref>
        </TopbarText>
      </TopbarWrapper>
    </TopbarContainer>
  );
};

export default Topbar;
