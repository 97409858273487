import React from "react";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogoScroll,
  NavItem,
  MobileIcon,
  NavMenu,
  NavLink,
  LogoTextContainer,
  LogoTextH1,
  LogoTextH3
} from "./NavbarElements";
import logo from "../../images/logo-ex-small.webp";

const Navbar = ({ toggle }) => {
  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogoScroll 
                to="home"
                smooth={true}
                spy={true}
                duration={500}
                exact="true"
                offset={-100}>
            <img src={logo} height="100%" alt=""/>
            <LogoTextContainer>
                <LogoTextH1>SMILE DENT</LogoTextH1>
                <LogoTextH3>Д-р Воденичарска</LogoTextH3>
            </LogoTextContainer>
          </NavLogoScroll>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLink
                to="home"
                smooth={true}
                spy={true}
                duration={500}
                exact="true"
                offset={-100}>
                Начало
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="team"
                smooth={true}
                spy={true}
                duration={500}
                exact="true"
                offset={-100}>
                За Нас
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="galery"
                smooth={true}
                spy={true}
                duration={500}
                exact="true"
                offset={-100}>Галерия</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="services"
                smooth={true}
                spy={true}
                duration={500}
                exact="true"
                offset={-100}>Услуги</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="contacts"
                smooth={true}
                spy={true}
                duration={500}
                exact="true"
                offset={-100}>Контакти</NavLink>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
