import React from "react";
import ImgService1 from "../../images/estetic-dental.webp";
import ImgService2 from "../../images/dental-tools.webp";
import ImgService3 from "../../images/kid-smile.webp";
import ImgService4 from "../../images/kid-aligners.webp";
import ImgService5 from "../../images/dental-crown.webp";
import ImgService6 from "../../images/endodontics.webp";
import ImgService7 from "../../images/periodontics.webp";
import ImgService8 from "../../images/dental-surgery.webp";
import ImgService9 from "../../images/botox.webp";
import ImgService10 from "../../images/fillers.webp";
import {
  ServicesContainer,
  ServicesInfoWrapper,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from "./servicesElements";

function Services() {
  return (
    <>
      <ServicesContainer id="services">
        <ServicesH1>Услуги</ServicesH1>
        <ServicesWrapper>
          <ServicesCard to="/aesthetics">
            <ServicesIcon src={ImgService1} />
            <ServicesH2>Естетична дентална медицина</ServicesH2>
            <ServicesInfoWrapper>
              <ServicesP>Зъбни фасети, Бондинг, Избелване на зъби</ServicesP>
            </ServicesInfoWrapper>
          </ServicesCard>
          <ServicesCard to="/kids">
            <ServicesIcon src={ImgService3} />
            <ServicesH2>Детска дентална медицина</ServicesH2>
            <ServicesInfoWrapper>
              <ServicesP>Лечение на кариеси, парадонтит и пулпит</ServicesP>
            </ServicesInfoWrapper>
          </ServicesCard>
          <ServicesCard to="/orthodontics">
            <ServicesIcon src={ImgService4} />
            <ServicesH2>Ортодонтия</ServicesH2>
            <ServicesInfoWrapper>
              <ServicesP>Лечение с лайнери, Лечение с брекети</ServicesP>
            </ServicesInfoWrapper>
          </ServicesCard>
          <ServicesCard to="/prosthesis">
            <ServicesIcon src={ImgService5} />
            <ServicesH2>Протетична дентална медицина</ServicesH2>
            <ServicesInfoWrapper>
              <ServicesP>Поставяне на корони, протези и мостови структури</ServicesP>
            </ServicesInfoWrapper>
          </ServicesCard>
          <ServicesCard to="/endodontics">
            <ServicesIcon src={ImgService6} />
            <ServicesH2>Ендодонтия</ServicesH2>
            <ServicesInfoWrapper>
              <ServicesP>Коренови лечения при парадонтит и пулпит</ServicesP>
            </ServicesInfoWrapper>
          </ServicesCard>
          <ServicesCard to="/periodontology">
            <ServicesIcon src={ImgService7} />
            <ServicesH2>Парадонтология</ServicesH2>
            <ServicesInfoWrapper>
              <ServicesP>Лечение на гингвит и парадонтит</ServicesP>
            </ServicesInfoWrapper>
          </ServicesCard>          
          <ServicesCard to="/fillers">
            <ServicesIcon src={ImgService10} />
            <ServicesH2>Хиалуронови филъри</ServicesH2>
            <ServicesInfoWrapper>
              <ServicesP>Висок клас филъри - Cytosial, Аmalian и Aprilain.</ServicesP>
            </ServicesInfoWrapper>
          </ServicesCard>          
          <ServicesCard to="/botox">
            <ServicesIcon src={ImgService9} />
            <ServicesH2>Ботокс</ServicesH2>
            <ServicesInfoWrapper>
              <ServicesP>Преди процедурата трябва...</ServicesP>
            </ServicesInfoWrapper>
          </ServicesCard>
          <ServicesCard to="/caries">
            <ServicesIcon src={ImgService2} />
            <ServicesH2>Кариесология</ServicesH2>
            <ServicesInfoWrapper>
              <ServicesP>Лечение на кариес</ServicesP>
            </ServicesInfoWrapper>
          </ServicesCard>
          <ServicesCard to="/surgery">
            <ServicesIcon src={ImgService8} />
            <ServicesH2>Хирургия</ServicesH2>
            <ServicesInfoWrapper>
              <ServicesP>Вадене на зъби, правила за следване от пациента</ServicesP>
            </ServicesInfoWrapper>
          </ServicesCard>
        </ServicesWrapper>
      </ServicesContainer>
    </>
  );
}

export default Services;
