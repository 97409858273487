import React from "react";
import { animateScroll as scroll } from 'react-scroll';
import Img1 from "../../images/prosthesis1.webp";
import Img2 from "../../images/prosthesis2.webp";
import Img3 from "../../images/prosthesis3.webp";
import Img4 from "../../images/prosthesis4.webp";
import Img5 from "../../images/prosthesis5.webp";
import Img6 from "../../images/prosthesis6.webp";
import Img7 from "../../images/prosthesis7.webp";
import Img8 from "../../images/prosthesis8.webp";
import Img9 from "../../images/prosthesis9.webp";
import Img10 from "../../images/prosthesis10.webp";
import Img11 from "../../images/prosthesis11.webp";
import Img12 from "../../images/prosthesis12.webp";
import Img13 from "../../images/prosthesis13.webp";
import Img14 from "../../images/prosthesis14.webp";
import Img15 from "../../images/prosthesis15.webp";
import Img16 from "../../images/prosthesis16.webp";
import Img17 from "../../images/prosthesis17.webp";
import Img18 from "../../images/prosthesis18.webp";
import Img19 from "../../images/prosthesis19.webp";
import Img20 from "../../images/prosthesis20.webp";
import Img21 from "../../images/prosthesis21.webp";
import { NavbarWithLinks } from "../../components/Navbar/navbarWithLinks.js";
import Topbar from "../../components/Topbar";
import {
  DefaultContainer,
  DefaultWrapper,
} from "../../components/Topbar/topbarElements";

const styles = {
  article: {
    color: "var(--c3)",
    fontSize: "1.2em",
    marginBottom: "40px",
  },
  h2: {
    color: "var(--c4)",
    textAlign: "center",
  },
  h3: {
    color: "var(--c4)",
  },
  img: {
    display: "block",
    marginLeft: "20px",
    maxWidth: "calc(100% - 40px)",
  },
  img2: {
    display: "block",
    margin: "2px",
    maxWidth: "calc(80%)",
    width: "300px",
  },
  flex: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
  },
};

function Prosthesis() {
  scroll.scrollToTop({
    duration: 0,
    delay: 0,
    smooth: 'linear',
  });
  return (
    <>
      <NavbarWithLinks disableIcon={true} />
      <Topbar />
      <DefaultContainer>
        <DefaultWrapper>
          <article style={styles.article}>
            <h2 style={styles.h2}>Протетична дентална медицина</h2>
            <h3 style={styles.h3}>
              Протетичната дентална медицина е дял от стоматологията, която се
              занимава с:
            </h3>
            <ul>
              <li>Възстановяване на зъби с единични корони. </li>
              <li>
                Възстановяване на липсващи зъби посредством мостови конструкции
                - временни (пластмасови), постоянни (металокерамични, керамични,
                метални, циркониеви и други).{" "}
                <ul>
                  <li>Адхезивен мост</li>
                </ul>
              </li>
              <li>
                Възстановяване на липсващ зъб/и посредством частична или цяла
                протеза.
                <ul>
                  <li>Плакови</li>
                  <li>Моделно ляти</li>
                  <li>Назъбни</li>
                  <li>Имедиатни</li>
                  <li>Протези върху импланти</li>
                  <li>
                    Какви неудобства или проблеми може да възникнат при
                    ползването на нова зъбна протеза?
                  </li>
                  <li>Продължителност на ползване на зъбната протеза</li>
                  <li>Как да поддържаме и да се грижим за зъбните протези?</li>
                </ul>
              </li>
            </ul>

            <h3 style={styles.h3}>
              Кога е необходимо да се възстанови зъб с корона?
            </h3>
            <ul>
              <li>Зъбът има голямо разрушение или голяма пломба</li>
              <li>След проведено кореново лечение</li>
            </ul>
            <p>
              {" "}
              Когато зъбите в следствие на голямо разрушение са с малко здрави
              зъбни тъкани, са предразположени към отчупване. Ако не бъдат
              укрепени навреме, е възможно счупване на част от короната и/или
              корена му. Колкото по-дълбоко е счупването, толкова по-трудно е
              възстановяването на зъба и по-лоша е неговата прогноза. Ако зъб с
              един корен се фрактурира (сцепи) надлъжно, се налага да бъде
              изваден. Ако многокоренов зъб се фрактурира (сцепи) надлъжно,
              понякога е възможно запазването на единия му корен и
              възстановяването му с коронка. Коронката предотвратява отчупването
              и запазва останалите здрави части от зъба. При големи разрушения
              на зъба, но достатъчно запазени зъбни тъкани, има показания за
              овърлей.
            </p>
            <p>
              {" "}
              Възстановяване на зъбите посредством коронка се осъществява след
              предварително изпиляване на зъба под формата на пънче. След, което
              се взема отпечатък от пънчето и се изработва временна корона от
              пласмаса. Изработването й става по{" "}
              <b>директен или индиректен метод:</b>{" "}
            </p>
            <h3 style={styles.h3}>Директен метод (става на момента)</h3>
            <ul>
              <li>Взима се отпечатък преди да се изпили зъба.</li>
              <li>След проведено кореново лечение.</li>
              <li>Изпилява се конкретният зъб.</li>
              <li>
                Във взетият отпечатък се поставя пластмаса и се връща отново в
                устата, като тя се оформя по подготвеното пънче и заема формата
                на естествения зъб.
              </li>
            </ul>
            <img src={Img1} style={styles.img} alt=""/>
            <h3 style={styles.h3}>Индиректен метод (става след 3 дни)</h3>
            <ul>
              <li>Взима се отпечатък от вече изпиленият зъб.</li>
              <li>
                Изпраща се в лаборатория, където се изработва от зъботехник.
              </li>
              <li>Поставяне и циментиране с временен цимент.</li>
            </ul>
            <p>
              {" "}
              Изработката на временната корона има голямо значение за оформянето
              на меките тъкани около бъдещата постоянна корона. Временната
              корона престоява в устата от една седмица до месец, взависимост от
              клиничният случай. След като изтече този срок се взима отпечатък
              от зъбното пънче, за да се изработи постоянната корона. Следва
              нейното поставяне и циментиране с постоянен цимент.{" "}
            </p>
            <p>
              {" "}
              Според материала, от който са изработени коронките те могат да
              бъдат:{" "}
            </p>
            <ul>
              <li>Пластмасови (временни корони)</li>
              <li>Изцяло метални</li>
              <li>
                Бленд корони - само външната (видимата) част е покрита съответно
                с пластмаса или керамика, а останалата част е метална
              </li>
              <li>
                Металокерамични - повърхността към пънчето е метална и е покрита
                от всички страни с керамика
              </li>
              <li>Изцяло керамични - изработени само от керамика </li>
              <li>Циркониеви - изцяло от цирконий</li>
            </ul>
            <div style={styles.flex}>
              <img src={Img10} style={styles.img2} alt="" />
              <img src={Img2} style={styles.img2} alt="" />
              <img src={Img3} style={styles.img2} alt="" />
              <img src={Img5} style={styles.img2} alt="" />
              <img src={Img6} style={styles.img2} alt="" />
            </div>
            <h3 style={styles.h3}>Възстановяване на липсващ зъб/и </h3>
            <p>
              {" "}
              Поради една или друга причина през нашият живот изгубваме зъби.
              Когато това стане имаме четири възможности за възстановяване на
              дъвкателната функция.{" "}
            </p>
            <h3 style={styles.h3}>
              1. Възстановяване на липсващия зъб/и посредством мостова
              конструкция
            </h3>
            <p>
              {" "}
              Зъбният мост представлява неподвижна фиксирана конструкция и се
              състои от зъбни корони, които покриват зъбите от двете страни на
              липсващия с висяща част между тях, която го замества. Зъбният мост
              се циментира към съществуващите зъби, върху които се поставя. За
              да може това да стане възможно съседните на липсващия зъб/и трябва
              да се подготвят като се изпилят под формата на зъбно пънче. Този
              вариант е подходящ, когато съседните два зъба са с големи пломби,
              разрушения или кореново лекувани. Ако съседните два зъба са
              напълно здрави, поставянето на такъв тип мостова конструкция не се
              препоръчва. Тук възниква възможността да се постави така
              нареченият{" "}
            </p>
            <p>
              {" "}
              Зъбният мост е здрав, същевременно естетически издържан и
              възстановява функцията на липсващите зъби. Дългосрочността на
              възстановяването с мостова конструкция зависи основно от:{" "}
            </p>
            <ul>
              <li>Оралната хигиена.</li>
              <li>Здравето на тъканите около зъбите.</li>
              <li>
                Височината и големината на зъбното пънче, което ще определя
                неговата задържащата способност.
              </li>
            </ul>
            <p>
              {" "}
              Един от основните недостатъци на мостовата конструкция е това, че
              ако се появи проблем в един от зъбите носещи мостовата
              конструкция, той трябва да се свали изцяло и в повечето случай
              подменен с нов. Такава причина може да бъде неадекватно кореново
              лечение, възникване на кариес на някое от пънчетата, неадекватна
              изработка на мостовата конструкция и др.{" "}
            </p>
            <img src={Img4} style={styles.img2} alt="" />
            <p>
              {" "}
              Според материала, от който са изработени мостовите конструкции те
              могат да бъдат:{" "}
            </p>
            <ul>
              <li>Пластмаса (временни мостове)</li>
              <li>Изцяло метални</li>
              <li>
                Бленд мостове - само външната (видимата) част е покрита
                съответно с пластмаса или керамика, а останалата част е метална
              </li>
              <li>
                Металокерамични - повърхността към пънчето е метална и е покрита
                от всички страни с керамика
              </li>
              <li>Изцяло керамични - изработени само от керамика</li>
              <li>Циркониеви - изцяло от цирконий</li>
              <li>
                Комбинация от метал, блендкерамика, блендпластмаса
                металокерамика{" "}
              </li>
            </ul>
            <div style={styles.flex}>
              <img src={Img8} style={styles.img2} alt="" />
              <img src={Img9} style={styles.img2} alt="" />
              <img src={Img7} style={styles.img2} alt="" />
              <img src={Img10} style={styles.img2} alt="" />
              <img src={Img11} style={styles.img2} alt="" />
              <img src={Img12} style={styles.img2} alt="" />
            </div>
            <h3 style={styles.h3}>Адхезивен мост</h3>
            <p>
              {" "}
              Когато съседните на липсващия зъб са напълно здрави (интактни) или
              имат обтурации (пломби) към страната на липсващия, подходящ
              вариянт на избор е т.нар. адхезивен мост. Той е алтернатива на
              мостовата конструкция и импланта.{" "}
            </p>
            <p>
              {" "}
              Какво представлява? Адхезивните мостове са иновация в
              стоматологията, чрез която е възможно постигане на висока
              естетика, стабилност и здравина без да се изпиляват два съседни
              зъба на липсващия. Как се постига? Чрез високоестетичните
              ГЛАСФИБРОВЛАКНА. Те се опъват между двата съседни зъба, като се
              залепят с фотополимер. Те служат като основа върху, която се
              изгражда зъб също от фотополимер. Когато двата съседни зъба имат
              вече пломби, това улеснява поставянето на влакната. Премахва се
              част от пломбата на двата съседни зъба и влакната се поставят в
              създадената форма, като се простират между двата зъба.
              ГЛАСФИБРОВЛАКНАТА са издръжливи на опън и натиск. Адхезивен мост
              може да бъде направен и когато съседните зъби са наклонени или с
              много къси корони и няма достатъчно място за поставяне на имплант
              или направа на класически мост. Лечението с адхезивни мостове
              протича в едно посещение. Постига се максимален естетичен
              резултат, поради липсата на метал във възстановяването.
              Адхезивните мостове имат значително ценово предимство в сравнение
              с мостовете от металокерамика, прескерамика и цирконий. Ако в
              бъдеще решите да си поставите имплант, можете да го направите,
              като адхезивният мост се премахва без последствия за носещите
              зъби. За направата на адхезивни мостове са необходими определени
              условия. Възможно е заместване на един или два липсващи зъба.
              Лисващият зъб (зъби) не трябва да е последен в зъбната редица.
              Липсващият зъб може да е както във фронталната област така и в
              страничната зона. За изграждане на адхезивни мостове, използвам
              гласфибровлакна everStickC&B на японската високотехнологична
              компания GC. Освен при направата на адхезивни мостове, използвам
              гласфибровлакната и за естетично шиниране при подвижност на зъбите
              или след ортодонтско лечение, местопазители при деца и
              подрастващи, както и за изграждане на силно разрушени зъби.{" "}
            </p>

            <img src={Img18} style={styles.img} alt="" />
            <h3 style={styles.h3}>
              2. Възстановяване на липсващ зъб/и посредством частична или цяла
              протеза
            </h3>
            <ul>
              <li>
                Плакови - изцяло от пластмаса, като те могат да бъдат от по-мека
                пластмаса (силиконови протези) или по-твърда.
              </li>
              <li>
                Моделно ляти - изработва се метален скелет върху, който се
                поставя платсмасовата плака и зъби.
              </li>
              <li>
                Протези фиксирани върху импланти - използват се при липса на
                всички зъби и се закрепват на предварително поставени импланти.
              </li>
            </ul>
            <p>
              <b>Плаковите</b> протези са най-често срещаните протези. Те са
              лесно приложими за повечето деформации, достъпни и осигуряват
              възстановяване на естетиката и храненето. След анализ на
              ситуацията в устата се пристъпва към нейната изработка.
              Пластмасата, от която се изработват може да бъде стандартна
              (твърда) или мека така наречените TERMO SENS протези, които са
              по-гъвкави и по-меко предават дъвкателното налягане. Куките, с
              които се задържат частичните протези могат да бъдат метални или
              пластмасови. Горната тотална протеза се задържа благодарение на
              ефекта на вакуум. Долната протеза се закрепва по-трудно поради:{" "}
            </p>

            <ul>
              <li>
                Езика, който ограничава повърхността, с която зъбната протеза
                контактува.
              </li>
              <li>
                По-често срещаната резорбция (стопяване) на костта на долната
                челюст с годините и вследствие на загуба на зъбите.
              </li>
              <li>Множеството мускули, които се захващат за долната челюст.</li>
            </ul>
            <p>
              {" "}
              Решение в този случай може да е поставяне на зъбни импланти на
              долната челюст, върху които да се закрепи нова зъбна протеза.{" "}
            </p>
            <h3 style={styles.h3}>Етапи на лечение с плакови протези</h3>
            <ul>
              <li>Вземане на отпечатък от горна и/или долна челюст </li>
              <li>
                Вземане на по-прецизен отпечатък с лъжица направена
                индивидуално, за съответният пациент по предварително взетият
                отпечатък при първото посещение.{" "}
              </li>
              <li>
                Вземане на височина на захапката с направения прототип на
                протезата. При частичните протези този етап в някой случай е
                предварително определен от наличните зъби, влизащи в контакт. В
                това посещение се определят формата, големината и цвета на
                изкуствените зъби. Когато има налични зъби се взимат впредвид
                техните особености. При пълна липса на зъби се отчитат
                индивидуалните особености на пациента (цвят на кожата, форма и
                големина на лицето) и неговите желания. При частичните протези в
                този етап става уточняването на средствата за задържане на
                протезата (куки, стави, телескоп коронки и др.), които са били
                избрани при планирането на протезата. След този етап прототипа
                се връща в лабораторията за да се наредят изкуствените зъби.{" "}
              </li>
              <li>
                Проба с наредени изкуствени зъби. В този етап пациентът може да
                види как ще изглежда и при необходимост да се направят корекции.{" "}
              </li>
              <li>
                Поставяне на готовата протеза в устата на пациента. Правят се
                тестове, за да се види дали протезата ляга върху протезното поле
                равномерно, ако е необходимо се правят корекции. Преглеждат се
                също и зъбните контакти и зъбите се изпиляват леко до получаване
                на равномерни контактни повърхности в ляво и дясно. На
                следващият ден е желателно да се направи контролен преглед, за
                да се установи адаптацията към протезата и ако има ранички да се
                изпилят и коригират причинните места по протезната плака.
                Поставянето на протеза особено когато е за пръв път е важен
                момент, защото конструкцията е нова, обемна и пациента трябва да
                премине през различно дълъг период на адаптиране. Стоматологът
                дава указания как да се поставя и сваля протезата, как да се
                измива и съхранява, обяснява се начина на хранене с протези
                (двустранно дъвчене) и се предупреждава пациента, че е възможно
                след първите едно две хранения да се получи нараняване на
                лигавицата. Свикването с първият чифт зъбни протези е
                индивидуално и е в рамките на 15 дни до 1 месец. При добра и
                качествена изработка на цели или частични протези, упоритост и
                грижа от страна на пациента, те могат да осигурят пълноценно
                хранене, говор и естетика за дълъг период от време.{" "}
              </li>
            </ul>
            <div style={styles.flex}>
              <img src={Img15} style={styles.img2} alt="" />
              <img src={Img14} style={styles.img2} alt="" />
              <img src={Img13} style={styles.img2} alt="" />
            </div>
            <h3 style={styles.h3}>Моделно ляти протези</h3>
            <p>
              Това са частични протези, които са изработени от метал и
              пластмаса. При тях скелета и куките използвани за задържане на
              протезата са метални, а видимите части и зъбите са пластмасови.
              При изцяло пластмасовите протези протезната плака покрива цялото
              небце на горната челюст или областта под езика на долната челюст,
              което води до неудобство, чувство за голямо чуждо тяло в устата,
              намаляване на усещанията за вкус, топло и студено. При моделно
              лятите протези тази плака е заменена от метална скара за горна
              челюст или свързващ бюгел за долна челюст. По този начин комфорта
              и удобството за пациента е максимален. Усещанията за вкуса и
              топлината на храната са максимално близки до нормалните. С този
              тип протези пациентите свикват много бързо, но пък са трудни за
              изработка и са с по-висока цена. Могат да се използват при всички
              дефекти на зъбните редици, при които има достатъчен брой здрави
              зъби, който да носят протезата. Това се изисква, защото тези
              протези са много стабилни в устата и могат да имат съвсем
              минимални движения. Те се осигуряват чрез изработката на
              индивидуално отлети куки от метал, които са съобразени с формата и
              особеностите на зъбите на отделния човек. Планирани прецизно и
              изработени качествено, те възстановяват пълноценното хранене,
              говора и естетиката.
            </p>
            <img src={Img19} style={styles.img} alt="" />
            <h3 style={styles.h3}>Етапи на лечение:</h3>
            <ul>
              <li>
                Вземане на отпечатък от горна и долна челюст. В зъботехническата
                лаборатория се отливат гипсови модели на челюстите. В този етап
                се изработват индивидуални лъжици и се прави основният анализ на
                протезата. Определят се посоката на поставяне и сваляне на
                протезата, опорните зъби, техните ретенционни места, които ще
                служат за задържане на куките и се избира техният вид.{" "}
              </li>
              <li>
                Вземане на по-прецизен отпечатък с лъжица направена
                индивидуално, за съответният пациент по предварително взетият
                отпечатък при първото посещение. Отливат се нови гипсови модели.
                Върху тези модели се изработва металния скелет и куките, които
                са едно цяло.
              </li>
              <li>
                Вземане на височина на захапката с направения прототип на
                протезата. Металният скелет се изпробва в устата на пациента.
                Важно е той да приляга правилно към устната лигавица без да я
                травмира. Куките също се изпробват дали плътно прилягат върху
                опорните зъби и дали задържат достатъчно добре протезата. При
                частичните протези определянето на височината на захапката в
                някой случай е предварително определен от наличните зъби,
                влизащи в контакт. В това посещение се определят формата,
                големината и цвета на изкуствените зъби. Когато има налични зъби
                се взимат впредвид техните особености. След този етап прототипа
                се връща в лабораторията за да се наредят изкуствените зъби.{" "}
              </li>
              <li>
                Проба с наредени изкуствени зъби. В този етап пациентът може да
                види как ще изглежда и при необходимост да се направят корекции.{" "}
              </li>
              <li>
                Завършената протеза се поставя в устата. При нея се коригират
                единствено дъвкателните повърхности на зъбите, а металният
                скелет не се пипа. Дават се указания как да се поставя и сваля,
                съхранява и почиства, а също и за поддържане на добра орална
                хигиена.{" "}
              </li>
            </ul>
            <img src={Img16} style={styles.img} alt="" />
            <h3 style={styles.h3}>Назъбни протези</h3>
            <p>
              Отвън протезите изглеждат като стандартни цели протези, но по
              долната си повърхност имат отвори, в които влизат специално
              подготвени опорни зъби покрити с метални кепета, като те остават
              скрити под протезите. Този тип протези се изработват в случаи,
              когато има останали само няколко зъба в челюстта, и те са
              разпределени неблагоприятно или не биха могли да носят мостова
              конструкция. По този начин, целите протези са много по-стабилни и
              комфортни за пациента.{" "}
            </p>
            <p>
              Друго основно предимство е високата естетика на тези протези
              поради факта, че металните кепета са изцяло скрити под протезата и
              не се виждат. Другото особено ценно предимство на назъбните
              протези е, че при проблем с някой от зъбите под тях не е
              необходимо да се сменя протезата. Зъбът при необходимост се
              изважда, а мястото му в протезата се запълва в кабинета или
              зъботехническата лаборатория и пациента си остава с протезата.{" "}
            </p>
            <img src={Img17} style={styles.img} alt="" />
            <h3 style={styles.h3}>Имедиатни протези</h3>
            <p>
              {" "}
              Имедиатните протези са стандартни плакови протези от акрилна
              пластмаса, които могат да бъдат както цели, така и частични.
              Специфичното за тях е, че се изработват по специална технология
              още преди премахването на зъбите на пациента. По този начин,
              когато пациента дойде в кабинета за изваждане на зъби, неговата
              протеза вече е готова и се поставя веднага след изваждането
              (екстракцията). Това осигурява на пациентите възможността да не
              ходят нито ден без зъби и да могат уверено да извършват
              ежедневните си задачи, да се хранят и усмихват спокойно.
              Имедиатните протези се носят в периода на заздравяване на раните,
              който е около 1 до 2 месеца след изваждане на зъбите. След това те
              трябва или да се подменят с нови дефинитивни, или да се уплътнят и
              да се превърнат в постоянни.
            </p>
            <h3 style={styles.h3}>Протези върху импланти</h3>
            <p>
              {" "}
              Дори в краен стадий на пълно обеззъбяване и значителна загуба на
              кост, съществува имплантологично решение на основата на вид
              базални импланти – фиксирана конструкция само върху 4 импланта от
              типа All-on-4. Първоначално на пациента се изработват тотални
              протези и се изчаква да свикне с тях. След, което се назначава
              скенер на едната или двете челюсти, който дава възможност да се
              планира прецизно мястото, на което ще се
              <b>поставят имплантите в костта. </b> Във всяка челюст се поставят{" "}
              <b>4 импланта. Изчаква се 3 месеца за остеоинтеграция</b>{" "}
              (импланта да се обгърне от костта). Протезата върху 4 импланта се
              фиксира (закрепва) със система тип „тик-так копче”. Тя се
              „закопчава” върху имплантите с помощта на тефлонови уплътнения и
              метални капачки, които са във вътрешната част на протезата. След
              това <b>значително се редуцират самите протези</b> , т.е. част от
              пластмасата се премахва. Например на горната челюст се премахва
              небцето, което прави протезата много по-удобна и приемлива. По
              този начин протезата се фиксира, т.е. движенията са ограничени и
              пациентът може да се храни и говори без притеснения.
            </p>
            <p>
              {" "}
              Ако в устата все още има зъби, но те не са годни за лечение и
              трябва да се извадят, могат да се изработят временни протези,
              които се слагат веднага след изваждането на зъбите и се носят
              докато костта заздравее напълно. Едва тогава се прави скенер и се
              изработват постоянните протези, в които в последствие се включват
              имплантите. По този начин се спестява на пациентите неудобството
              от ходене без зъби.{" "}
            </p>
            <p>
              <b>Протезите се свалят ежедневно </b> за да се почистят както те,
              така и имплантите под тях, защото <b> хигиената </b> е от
              първостепенна важност за продължителността на живота на имплантите
              в устата.{" "}
            </p>
            <img src={Img20} style={styles.img} alt="" />
            <img src={Img21} style={styles.img} alt="" />

            <p>
              <b>Зъбните протези способстват на пациента за</b> подобряване на
              естетиката и визията. Носенето на протези за заместването на
              липсващи зъби поддържа устните и бузите и коригира деформациите в
              мимиката на лицето, възникнали в резултат на липсващите зъби.
              Подобряването на външния вид и артикулацията води до по-добро
              самочувствие и увереност.{" "}
            </p>

            <h3 style={styles.h3}>
              Какви неудобства или проблеми може да възникнат при ползването на
              нова зъбна протеза?
            </h3>
            <p>
              {" "}
              Зъбните протези са решение при множество липсващи зъби, но в
              никакъв случай не бива да се очаква от пациента, че ще се чувства
              със зъбните протези както с естествените си зъби в устата.
              Стигайки до поставянето на зъбни протези в устата човек трябва да
              прояви нужното търпение за да се адаптира към тях, тъй като е
              възможно да изпитва известни неудобства като:{" "}
            </p>
            <ul>
              <li>
                Повишено слюноотделяне в рамките на първите 12-24 часа след
                първоначалното поставяне на зъбните протези. Причината за това
                повишено слюноотделяне е, че мозъкът на човек възприема
                първоначално протезата като храна и изпраща „съобщения“ към
                съответните жлези да повишат количеството слюнка.{" "}
              </li>
              <li>
                Ново направените зъбни протези могат да травмират малки участъци
                под протезите. С няколко корекции на протезата, след
                първоначалното й поставяне, подобни проблеми се отстраняват.{" "}
              </li>
              <li>
                Гаденето е проблем, който се случва рядко. Причините за гаденето
                при първоначално ползване на зъбни протези могат да са: хлабава,
                твърде дебела или широка зъбна протеза, която опира в задната
                част на небцето. Понякога гаденето при поставянето на зъбна
                протеза може да е на психологическо ниво. Този случай е
                най-неприятен, тъй като е извън контрола на стоматолога.
                Възможно решение в този случай е поставяне на импланти и направа
                на протеза, която не контактува с небцето.{" "}
              </li>
              <li>
                Възможно е възникване на инфекция на венеца и възпаления при
                цялостните протези, предизвикани от недобра хигиена.{" "}
              </li>
              <li>
                Загубата на вкус и чувствителност е често срещана при пациенти,
                които за първи път слагат зъбна протеза. Състоянието е временно.{" "}
              </li>
            </ul>
            <h3 style={styles.h3}>
              Продължителност на ползване на зъбната протеза
            </h3>
            <p>
              Една протеза, която първоначално или през първата година е била
              удобна, е възможно постепенно да престане да пасва добре в устата.
              Когато челюстта остане без зъби костта прогресивно се резорбира
              (стопява). При една нестабилната зъбна протеза процеса на
              резорбция на костта е по-засилен от ползването на дентална
              протеза, която пасва добре в устата. Когато протезата престане да
              пасва добре в устата следва да се погрижите за проблема по един от
              следните начини:{" "}
            </p>
            <ul>
              <li>
                Можете да използвате лепило за зъбни протези, но трябва да имате
                предвид, че това ще е ефективно при използване на малки
                количества лепило. Необходим е съвсем тънък слой върху зъбната
                протеза, който ще я стабилизира. Лепилото за протези може да
                компенсира само леки хлабавини в зъбната протеза и трябва да се
                има предвид, че това е временно решение и няма да реши проблема
                напълно.{" "}
              </li>
              <li>
                При съвременните технологии и материали зъбните протезите могат
                да бъдат уплътнени за по-добро пасване в устата. Тази процедура
                е по-евтина отколкото направата на нова протеза.{" "}
              </li>
              <li>
                Изработване на нова протеза. Финално можем да кажем, че една
                добре направена протеза може да издържи между 3 и 5 години.{" "}
              </li>
            </ul>

            <h3 style={styles.h3}>
              Как да поддържаме и да се грижим за зъбните протези?
            </h3>
            <p>
              {" "}
              Зъбите протези трябва да се свалят и мият с четка и сапун, а не с
              паста за зъби, защото повечето пасти за зъби имат абразив, който
              надрасква повърхността и скъсяват техния живот. Веднъж седмично
              протезите трябва да се потапят в разтвор за дезинфекция. В
              аптеките се продават таблетки за почистване на фирмите COREGA и
              PROTEFIX. Протезата се поставя във вода и след това се слага
              таблетката за 30мин. Когато протезата не е в устата трябва да е
              във вода. Непрекъснатото изсушаване и мокрене на протезата скъсява
              нейния живот. Не е необходимо да носите зъбните протези
              непрекъснато, но е желателно да спите с тях. По този начин давате
              възможност на организма да привикне с тях и да ги приеме.
              Свалянето на протезата също така има значение за да се даде
              възможност на тъканите да се възстановят.{" "}
            </p>
          </article>
        </DefaultWrapper>
      </DefaultContainer>
    </>
  );
}

export default Prosthesis;
