import React from "react";
import { animateScroll as scroll } from 'react-scroll';
import Img12 from "../../images/procedure12.webp";
import Img13 from "../../images/procedure13.webp";
import Img14 from "../../images/procedure14.webp";
import Img15 from "../../images/procedure15.webp";
import Img16 from "../../images/procedure16.webp";
import Img17 from "../../images/procedure17.webp";
import Img18 from "../../images/procedure18.webp";
import Img19 from "../../images/procedure19.webp";
import Img20 from "../../images/procedure20.webp";
import Img21 from "../../images/procedure21.webp";
import { NavbarWithLinks } from "../../components/Navbar/navbarWithLinks.js";
import Topbar from "../../components/Topbar";
import {
    DefaultContainer,
    DefaultWrapper,
} from "../../components/Topbar/topbarElements";

const styles = {
    article: {
        color: "var(--c3)",
        fontSize: "1.2em",
        marginBottom: "40px",
    },
    h2: {
        color: "var(--c4)",
        textAlign: "center",
    },
    h3: {
        color: "var(--c4)",
    },
    img: {
        display: "block",
        marginLeft: "20px",
        maxWidth: "calc(100% - 40px)",
    },
    img2: {
        display: "block",
        margin: "2px",
        maxWidth: "calc(80%)",
        width: "300px",
    },
    flex: {
        marginLeft: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "flex-start",
    },
};

function Fillers() {
    scroll.scrollToTop({
      duration: 0,
      delay: 0,
      smooth: 'linear',
    });
    return (
        <>
            <NavbarWithLinks disableIcon={true} />
            <Topbar />
            <DefaultContainer>
                <DefaultWrapper>    
                    <article style={styles.article}>             
                        <h3 style={styles.h3}>Хиалуронови филъри</h3>
                        <p>Човешкото тяло  естествено произвеждат хиалуронова киселина, на която се дължи свежестта на кожата. С напредването на възрастта произвеждането на хиалуронова киселина се забавя драстично. В следствие на което кожата губи обем и плътност, което ни прави да изглеждаме по- стари и уморени.</p>
                        <p>Хиалуроновите филъри попълват изконсумираната хиалуронова киселина в подкожните депресии, запълва и изглажда статичните бръчки по повърхността на кожата.Филърите сами по себе си са хидрофилни и поемат вода, като по този начин те придават обем в инжектираната зона.Резултатът от инжектирането на хиалуроновия филър е мигновен и според скоростта, с която вашето тяло изконсумира хиалуроновата киселина ефектът се задържа между 6 и 12 месеца. При поставянето му в зоната, която не е третирана до този момент се активира естественият ензим в организма, който я разграждa (хиалуронидаза), вследствие на това филъра се задържа 3-4 месеца. При всяко следващо поставяне в тази зона се увеличава времето за неговото задъжане. Едно от най-големите предимства на хиалуроновите филъри е намаления риск от алергични реакции, тъй като тялото ни естествено произвежда хиалуронова киселина, която е основна съставка на филъра.</p>
                        <p>Филърите се комбинират идеално с ботулинов токсин, като токсина предхожда филъра и необходимо да има един месец разлика между тях.</p>
                        <p>Голямото разнообразие от филъри като марки и съставки на пазара позволява да се подбере най-подходящия филър за третираната зона.В зависимост от зоната на третиране,филърите са повече или по-малко омрежени (по-малко или повече връзки между молекулите на хиалуроновата киселина).</p>
                        <p>Зоните, които са подходящи за третиране с филър са: устни, скули, назолабиални линии, марионетни линии, ъгъл на долната челюст, брадичка, темпоралната област (депресията в областта на слепоочието) и носа.</p>
                        <p>Кога не се прилага процедурата?</p>
                        <ul>
                            <li>При бременност и кърмене.</li>
                            <li>При заболявания в остра фаза</li>
                            <li>При инфекции в третираната област</li>
                        </ul>
                        <p><b>Как протича процедурата с хиалуронови филъри? </b></p>
                        <p>След прилагане на локална упойка( това най често става с кремове съдържащи лидокаин), лекарят инжектира продукта под кожата, на различна дълбочина, така че да се възстанови загубения обем, където е необходимо. Инжекциите могат да причинят лек дискомфорт (парене, временен отток, малки посинявания), които преминават бързо. Инжектирането на филър може да стане с игла или канюла взависимост от третираната зона и желаният ефект. </p>
                        <p><b>Преди процедура:</b></p>
                        <ul>
                            <li>Избягвайте аспирин за 2 седмици.</li>
                            <li>Избягвайте ибупрофен и алкохол за 2 дни.</li>
                            <li>По възможност, измийте лицето си и не носете грим преди процедурата.</li>
                        </ul>
                        <p><b>След процедура:</b></p>
                        <ul>
                            <li>Често срещани са зачервявания по третираното място, които отминават до няколко дена. Ако зачервяванията не отминат, консултирайте се със вашият лекар.</li>
                            <li>Не масажирайте третираните места.</li>
                            <li>Не излагайте третираното място на топлина.</li>
                            <li>Избягвайте обилното потене (спорт, солариум,парна баня), в деня на процедурата и няколко дни след нея.</li>
                            <li>24 часа след процедурата не използвайте козметични продукти.</li>
                            <li>Може да приложите студен компрес на мястото за 15 минути на всеки няколко часа, за да намалите дискомфорта, подуване или посиняване. Посиняванията отминават за 7-10 дена.</li>
                            <li>Употребата на арника монтана може да облекчи подуванията или посиняванията.</li>
                            <li>Изключително важно е след процедурата да приемате достатъчно количество вода в продължение на 2 седмици. (Минимум по 2 л. на ден).</li>
                            <li>При третиране на скули първата нощ след процедурата ще трябва да спите по гръб и да не се навеждате.</li>
                            <li>При третиране на устни не трябва да пиете от шише и  да не пушите в същия ден. </li>
                        </ul>
                        <p>Смайл Дент работи с филъри от висок клас- Cytosial, Аmalian и Aprilain. При конкретни предпочитания към филър може да го заявите на посочения телефон на денталното студио.</p>
                        <p>Обхватът на Cytosial съдържа три дермални пълнителя - Cytosial Medium, Cytosial Deep Wrinkle и Cytosial Volume. Трите продукта имат една и съща концентрация и обем, но имат различна омреженост.</p>
                        <p>Постигането на естествен вид и прецизни детайли е важно, за да се съхрани идентичността на всеки клиент. Затова препоръчвам хиалуронови филъри с високо качество и дълготраен ефект. Резултатите са очевидни - плътност, обем, ясен контур и естествени форми.</p>
                        <p>Ето една наша пациентка, при която са третирани устните, назолабиалните гънки и скулите.В устните са поставени 1мл  Cytosial Medium.В скулите и назолабиалните гънки  Cytosial Deep Wrinkle и Cytosial Volume 2мл.</p>
                        <div style={styles.flex}>
                            <img src={Img12} style={styles.img2} alt=""/>
                            <img src={Img13} style={styles.img2} alt="" />
                        </div>
                        <p>Първата снимка е в деня на консултацията.При тази пациентка има видима асиметрия на долната челюст.За асиметричната загуба на обем допринася и спането на едната страна.Обикновенно разчертавам пациентите и им показвам къде имат загуба на обем.Обсъждаме заедно плана на лечение и при следващото посещение поставям филърите.По желание на пациента може да се постави филъра и в същото посещение.Тенденцията при поставяне на хиалроновата киселина е да се създаде така наречения V-shape на лицето.</p>
                        <p>При третирането на устните използвам най-често така наречената руска техника. При тази техника устната става плоска в профил и повдигната (леко се обръща), подчертава се контура на устните.При поставянето на филъра се получава лек оток с преходен характер.Някой пациентки са с по-чувствителна и тънка кожа, при тях може да се очаква повече зачервяване и оток. Всичко това е с преходен характер и минава в рамките на 2-3 дни.След минимум 7 дни се прави ретуш, ако е необходимо.</p>
                        <div style={styles.flex}>
                            <img src={Img14} style={styles.img2} alt="" />
                            <img src={Img15} style={styles.img2} alt="" />
                        </div>
                        <p>При третиране на носа се получават много добри резултати-изправят се гърбички, които се получават при вкостяването на носните кости, а също така може да се повдигне и върха на носа.</p>
                        <div style={styles.flex}>
                            <img src={Img16} style={styles.img2} alt="" />
                            <img src={Img17} style={styles.img2} alt="" />
                        </div>
                        <div style={styles.flex}>
                            <img src={Img18} style={styles.img2} alt="" />
                            <img src={Img19} style={styles.img2} alt="" />
                        </div><div style={styles.flex}>
                            <img src={Img20} style={styles.img2} alt="" />
                            <img src={Img21} style={styles.img2} alt="" />
                        </div>

                    </article>
                </DefaultWrapper>
            </DefaultContainer>
        </>
    );
}

export default Fillers;
