import React from "react";
import { animateScroll as scroll } from 'react-scroll';
import Img1 from "../../images/orthodontics1.webp";
import Img2 from "../../images/orthodontics2.webp";
import Img3 from "../../images/brackets1.webp";
import Img4 from "../../images/brackets2.webp";
import Img5 from "../../images/brackets3.webp";
import Img6 from "../../images/brackets4.webp";
import Img7 from "../../images/brackets5.webp";
import Img8 from "../../images/brackets6.webp";
import Img9 from "../../images/brackets7.webp";
import Img10 from "../../images/brackets8.webp";
import Img11 from "../../images/brackets9.webp";
import Img12 from "../../images/brackets10.webp";
import Img13 from "../../images/brackets11.webp";
import Img14 from "../../images/brackets12.webp";
import { NavbarWithLinks } from "../../components/Navbar/navbarWithLinks.js";
import Topbar from "../../components/Topbar";
import {
  DefaultContainer,
  DefaultWrapper,
} from "../../components/Topbar/topbarElements";

const styles = {
  article: {
    color: "var(--c3)",
    fontSize: "1.2em",
    marginBottom: "40px",
  },
  h2: {
    color: "var(--c4)",
    textAlign: "center",
  },
  h3: {
    color: "var(--c4)",
  },
  img: {
    display: "block",
    marginLeft: "30px",
    maxWidth: "calc(100% - 40px)",
  },
  img2: {
    display: "block",
    margin: "10px",
    width: "300px",
  },
  flex: {
    marginLeft: "20px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  center: {
    textAlign: "center"
  }
};

function Orthodontics() {
  scroll.scrollToTop({
    duration: 0,
    delay: 0,
    smooth: 'linear',
  });
  return (
    <>
      <NavbarWithLinks disableIcon={true} />
      <Topbar />
      <DefaultContainer>
        <DefaultWrapper>
          <article style={styles.article}>
            <h2 style={styles.h2}>Ортодонтия</h2>
            <h3 style={styles.h3}>Лечение с АЛАЙНЕРИ</h3>
            <p>
              {" "}
              Денталните алайнери са нов метод на избор при лечение на различни
              зъбни деформации. Те представляват невидими, подвижни и естетични
              апарати направени от лека прозрачна пластмаса, които се носят
              върху зъбите за определен период от време, обикновено няколко
              месеца. Алайнерите не съдържат никакви метални части няма винтове,
              тел или куки за прикрепяне. Те са вакумни апарати и плътно
              прилепват по вашите зъби. В тези апарати е заложена позицията на
              всеки един зъб. Всяка седмица се сменят за горна и долна челюст. В
              резултат зъбите Ви са подравнени и имате нова блестяща естествена
              усмивка. За предсказуемост на резултатите всеки пациент може да
              получи два вида мотивационни модели (дигитални и полимерни).
              Мотивационните модели представляват пластмасови макети на зъбите,
              подредени според предвидения за пациента план на лечение – така
              както ще изглеждат след завършване на лечението.
            </p>
            <img src={Img1} style={styles.img} alt=""/>
            <h3 style={styles.h3}>Етапи на лечение:</h3>
            <ul>
              <li>
                Преглед. Обсъждане на плана на лечение с пациента с алайнери и с
                брекети. Вземане на отпечатък от зъбите на пациента. Изпращане
                на отпечатъците в лаборатория.{" "}
              </li>
              <li>
                Получаване на анализ от лабораторията за горна и долна челюст. В
                анализа са описани колко стъпки ще са необходими за да се
                достигне крайният резултат отразен в мотивационните модели.
                Всяка стъпка се състой от три шини - soft, medium, hard. Обсъжда
                се с пациента плана на лечение, ако той е съгласен се преминава
                към следващия етап.{" "}
              </li>
              <li>
                Получаване от лабораторията броят шини необходим да се постигне
                крайният резултат. Започва се с първа стъпка в горна и долна
                челюст. Всяка шина се носи една седмица 24/7. Свалят се само при
                хранене и миене на зъбите. Необходимо е да се слагат чисти шини
                на чисти зъби. С алайнерите може да се пие само вода. Би било
                добре да имате отделна четка за почистване на алайнерите. Всяка
                седмица пациентът е на контролен преглед и получава следващите
                шини. Така до получаване на крайният резултат. Повечето лечения
                траят между три и девет месеца. За да имате добри бързи
                резултати трябва да сте изцяло отдадени на лечебния процес и да
                носите алайнерите.{" "}
              </li>
              <li>
                След края на лечението пациентът получава т.нар ретайнери, който
                поддържат новата позиция на зъбите. Те се носят само през нощта.
                Генетично е заложена позицията на всеки зъб в устната кухина. С
                алайнерите може да променим позицията на зъбите, но не можем да
                променим генетиката. Първите три години след приключва на
                лечението стремежът на зъбите да се върнат към предишната си
                позиция е най-силна. При деца в пубертета този процес продължава
                до 18 годишна възраст, до завършване на основния растеж. Затова
                носенете на ретайнерите е най-важният етап от лечението. Тяхното
                носене след първите три години след завършване на лечението може
                да се разреди, но продължава през целия живот. За деца в
                пубертета носенето на ретайнерите всяка вечер трябва да продължи
                до завършване на основният растеж (18г) след което може да се
                разреди, но продължава до края на живота.{" "}
              </li>
            </ul>
            <img src={Img2} style={styles.img} alt=""/>
            <br/>
            <h3 style={styles.h3}>Брекети</h3>
            <p>Лечението с брекети може да се осъществи на всяка възраст.С тях могат да се решават не само естетически проблеми, но и функционални.Много от деформациите в детска вързаст се задълбочават във времето и е необходимо, като се открият да се лекуват с подготвителен апарат,а може би с няколко, взависимост от възрастта на детето.При някои деформации фукцията е много затруднена и тогава ортодонтското лечение е наложително за правилното развитие на двете челюсти,не само на ниво зъби, но и на ниво кости, за да се избегнат асиметриите.Защо да изберем брекетитете като начин на лечение? С брекетите може да се постигне много добро и конкретно преместване на даден зъб до желаната позиция.Зъбите посредством брекетите се местят корпусно, тоест корена и короната на съответният зъб се преместват заедно.Това е една от основните разлики със шините използвани в детска възраст,но те от своя страна при наличие на временно съзъбие могат да помогнат за разрешаване на някой от функционалните проблеми и деформации, които ще се задълбочат с времето.</p>
            <p>Понякога преди лечението с брекети е необходимо да се постави подготвителен апарат.Например апарата за бързо разширение, когато горната челюст е стеснена и не отговаря по размер на долната.Друг такъв апарат, е апарата за дистализиране на зъби- при много голямо струпване на зъби  в предния участък на зъбната дъга, задните зъби трябва да отидат назад за да могат да се подредят предните.Такива деформации понякога немогат да се решат по този начин и се налаг да се извадят зъби, поради недостиг на място. При смяната на временните с постоянните зъби, поради разлика в техният размер се освобождава място,което в повечето случай е необходимо да се запази.За запазването на освободното място в страничният участък се налага да се поставят т.нар. транспалатинална дъга за горна челюст и лингвална дъга за долна челюст. </p>
            <div style={styles.flex}>
              <div style={styles.center}>
                <img src={Img3} style={styles.img2} alt=""/>
                <span><i>Лингвална дъга</i></span>
              </div>
              <div style={styles.center}>
                <img src={Img4} style={styles.img2} alt=""/>
                <span><i>Транспалатинална дъга</i></span>
              </div>
            </div>
            <br/>
            <div style={styles.flex}>
              <img src={Img5} style={styles.img2} alt=""/>
              <img src={Img6} style={styles.img2} alt=""/>
            </div>
            <p>Апарат за разширение на горна челюст. Тези апарати се изработват посредством дигитален дизайн  и са индивидуални.Този апарат служи и за опора за подреждането на зъбите във фронталният участък посредством брекети.</p>
            <div style={styles.flex}>
              <img src={Img7} style={styles.img2} alt=""/>
              <img src={Img8} style={styles.img2} alt=""/>
            </div>
            <p>Апарати за преместване на страничните зъби назад:</p>
            <ul>
              <li>при недостиг на място във фронталният участък.</li>
              <li>когато трябва да се преместят дадени зъби при липса на зародиш или завъртяна средна линия.</li>
            </ul>
            <p>Съществуват няколко вида брекети взависимост от материала,от който са направени-метални, керамични и фибростъкло.Керамичните брекети и от фибростъкло са естетични.Разликата между тях, е че керамичните са по-матови и са направени от материал,който като цвят е най-често срещан в човешкото съзъбие, докато при тези от фибростъкло прозира цвета на вашият зъб. Естетичните брекети са по-крехки и когато ще се извършват големи движения на зъбите има риск от отчупване и разлепване.</p>
            <div style={styles.flex}>
              <div style={styles.center}>
                <img src={Img9} style={styles.img2} alt=""/>
                <span><i>Фибростъклени брекети</i></span>
              </div>
              <div style={styles.center}>
                <img src={Img10} style={styles.img2} alt=""/>
                <span><i>Керамични и метални брекети</i></span>
              </div>
            </div>
            <p>Системата брекети, с която работим в Смайл Дент се нарича Straight wire. При нея позицията на зъба е заложена в самия брекет и с помоща на дъгата зъбът заема желаната позиция.Процедурата по натягане на брекетите, която е всеки месец представлява смяна на дъгата по размер,както и подмяна на ластичните лигатури така наречените ластичета, които държат дъгата в брекета.</p>
            <p>За да може да поставим брекети е необходимо да имаме налични повечето постоянни зъби.Преди процедурата на пациента се назначават две рентгенови снимки- панорамна и телерентгенография.След като двете смимки са готови на пациента се правят екстраорални снимки в профил и анфас, както и снимки на зъбите преди началото на лечението.Взимат се отпечатъци от зъбите.Дотук събраната информация служи да се направи план на лечение.Той се съгласува с пациента и при съгласие от негова страна се назначава посещение за поставяне на брекети. Самата процедура по поставяне трае около час и е неболезнена.</p>
            <p><b>Какво се чувства след поставяне на брекети?</b></p>
            <p>След поставянето на брекетите зъбите могат да се чувстват изтръпнали, като това е напълно нормално.При необходимост може да се пият обезболяващи.Всички топли храни и напитки увеличават чувствителността във зъбите, тъй като при лечението се изполват термоактивни дъги.При топло метала се разширява и оказва натиск върху зъбите.Обратно студените водят до свиване на метала и облекчаване на напрежението.</p>
            <p>Местенето на даден зъб е свързано със стопяване на кост, преместване на зъба в новата позиция и образуване на кост на предишното място.Резорбцията (стопяването) на костта е бърз процес, образуването й е доста по дълго във времето,средно между 3-6месеца.Това определя и продължителността на едно лечение.</p>
            <p>Всеки пациент след поставяне на брекети получава инструкции за поддържане на добра орална хигиена.Брекетите сами по себе си немогат да предизвикат кариес.Храната, която остава по тях обаче може.Затова е изключително важно поддържането на добра хигиена, което е често пренебрегвано.При пациенти с брекети четката за зъби трябва да се сменя по-често.След поставянето на брекети се забранява консумацията на твърди храни и отхапване поради риск от отлепване на брекетите.При отлепване на даден брекет или изваждане на дъгата е необходимо да се свържете с лекуващия лекар.</p>
            <p>Средно едно ортодонтско лечение с брекети трае около година, година и няколко месеца. Този срок се определя от вида деформация, както и от вързастта на пациента. Средното носене на подготвителният апарат е между 4-6 месеца. </p>
            <p>След приключване на ортодонтското лечение е необходимо пациента да носи така нареченият ретенционен апарат, защото зъбите се стремят да се върнат към началната си позиция.Тези ретенционни апарати могат да са фиксирани или снемаеми. Снемаемият апарат е вакумен и се изработва по зъбите на пациента, спи се през нощта с него. Фиксираният апарат се залепя по вътрешната повърхност на зъбите в горна и долна зъбна дъга. Избора на апарат се определя от началната деформация. Понякога се налага да се сложи така наречената активна ретенция, това е апарат тип „шина“, с който се спи (например при отворвена или дълбока захапка).</p>
            <div style={styles.flex}>
              <div style={styles.center}>
                <img src={Img11} style={styles.img2} alt=""/>
                <span><i>Фиксиран ретайнер</i></span>
              </div>
              <div style={styles.center}>
                <img src={Img12} style={styles.img2} alt=""/>
                <span><i>Снемаем вакумен ретайнер</i></span>
              </div>
              <div style={styles.center}>
                <img src={Img13} style={styles.img2} alt=""/>
                <span><i>Активна ретенция</i></span>
              </div>
            </div>
            <br/>
            <img src={Img14} style={styles.img} alt=""/>

          </article>
        </DefaultWrapper>
      </DefaultContainer>
    </>
  );
}

export default Orthodontics;
