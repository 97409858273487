import React from "react";
import { animateScroll as scroll } from 'react-scroll';
import Img1 from "../../images/kids1.webp";
import Img2 from "../../images/kids2.webp";
import { NavbarWithLinks } from "../../components/Navbar/navbarWithLinks.js";
import Topbar from "../../components/Topbar";
import {
  DefaultContainer,
  DefaultWrapper,
} from "../../components/Topbar/topbarElements";

const styles = {
  article: {
    color: "var(--c3)",
    fontSize: "1.2em",
    marginBottom: "40px"
  },
  h2: {
    color: "var(--c4)",
    textAlign: "center",
  },
  h3: {
    color: "var(--c4)"
  },
  img: {
    display: "block",
    marginLeft: "20px",
    maxWidth: "calc(100% - 40px)"
  },
};

function Kids() {
  scroll.scrollToTop({
    duration: 0,
    delay: 0,
    smooth: 'linear',
  });

  return (
    <>
      <NavbarWithLinks disableIcon={true} />
      <Topbar />
      <DefaultContainer>
        <DefaultWrapper>
          <article style={styles.article}>
            <h2 style={styles.h2}>Детска дентална медицина</h2>
            <p>
              Най-честата патология при децата е появата на кариес. За това
              способстват няколко основни фактора:
            </p>
            <ul>
              <li>
                Плоскостният контакт между зъбите, които водят до задържане на
                храна и по трудното й отсраняване. Тези контакти затрудняват и
                навременната диагностика.
              </li>
              <li>
                Дебелината на слоевете на млечните зъби - емайлът на зъбите е
                1мм във всички участъци.
              </li>
              <li>
                Неизградените хигиенни навици и затруднената комуникация в
                по-малка възраст.
              </li>
            </ul>
            <p>
              Когато кариесът се появи преди третата година на детето се нарича
              кариес на ранното детство.
            </p>
            <h3 style={styles.h3}>Кариес на ранното детство</h3>
            <p>
              Зъбният кариес{" "}
              <b>
                представлява увреждане и нарушаване на твърдите зъбни тъкани{" "}
              </b>
              резултат от киселините, получени при разграждането на захарите, с
              които се храним от микроорганизмите, живеещи около зъбите. За да
              причинят кариес, на киселините им е необходимо достатъчно време.
              Обикновено първите прояви на зъбния кариес се наблюдават след
              третата година на детето. Появата на кариес на по ранна възраст
              има две причини. Едната е по рядка и представлява наследствен или
              вроден дефект в изграждането на зъбите. По честата причина е
              неправилното поведение при отглеждането на детето след раждането.
            </p>
            <p>
              Неправилното поведение е свързано с условия за ранно поселване на
              кариесогенните микроорганизми (тези, които причиняват кариес) в
              устата на бебето и с продължителната задръжка на хранителни
              остатъци около зъбите. Детето се ражда със стерилна уста.
              Поселването на кариесогенните микроорганизми в устата на детето
              става от устата на майката, бащата или тези, които го отглеждат.
              Целувките по устата, облизването на биберона или лъжичката са
              директният път за пренасяне на микроорганизмите. Продължителната
              задръжка на хранителните остатъци се получава при продължително
              кърмене или хранене с шише (пълно с мляко, подсладена вода или
              сок), при което детето заспива. Тогава в устата около зъбите
              остава последната глътка, която продължително време е източник на
              храна за микроорганизмите, и започва активно образуване на
              киселини. Неизмитите зъби се атакуват по време на съня и бързо се
              разрушават.{" "}
              <b>
                За да с е избегне риска от развитието на така наречения кариес
                на ранното детство e ВАЖНО:
              </b>
            </p>
            <ul>
              <li>
                Шишето с биберон да се използва само за хранене, а не за
                приспиване или успокояване на детето. С него се дава само кърма,
                мляко или вода. Водата никога не се подслажда.
              </li>
              <li>
                По време на хранене детето да се държи на ръце, а не се оставя
                легнало в креватчето. Не трябва да заспива на гърдата на майката
                или шишето да се прикрепя до детето в леглото докато заспи.
                Сънят не е време за хранене.
              </li>
              <li>
                Веднага след кърмене детето да се отстранява от гърдата. Дори
                кърмата причинява кариес, когато остане дълго около зъбите.
              </li>
              <li>
                Удължаване на времето за кърмене или хранене с шише е една от
                основните причини за кариеса на ранното детство.
              </li>
              <li>
                Да не се допуска нощно хранене. През нощта може да се дава
                единствено неподсладена вода.
              </li>
              <li>
                След кърмене или хранене с шише зъбите на детето да се измиват.
                Това става със специални памучни накрайници за пръст, които
                могат да се намерят в аптеките, натопени в разредена 1:1
                кислородна вода с обикновена вода или дори само с вода. При
                липса на накрайници почистването може да се прави с намокрена в
                същия разтвор марля.
              </li>
              <li>
                След шестия месец детето трябва да се приучи за пиене с чаша
                (първоначално само за игра) и хранене с лъжичка.
              </li>
              <li>
                Крайният допустим срок за кърмене или хранене с шише е първата
                година. Отбиването трябва да става постепенно след шестия месец.
                Изхвърлянето на шишето и бибероните трябва да стане ритуал за
                първия рожден ден.
              </li>
              <li>
                Ключът към отбиването и отказването от шишето е търпението. Не
                трябва да се забравя, че и най-упоритият навик в тази възраст се
                премахва най-много за седем дни.
              </li>
              <li>
                Не трябва да се облизва лъжичката, биберона, да се пробва
                храната или да се почиства детето със слюнка. Това е
                най-сигурният път за пренасяне на кариесогенни микроорганизми в
                устата на детето.
              </li>
              <li>
                Първата четка за зъби трябва да се даде на детето от момента на
                пробива на първите зъби. Запознаването с нея трябва да е като
                игра, а обучението да се подкрепя от примера на родителите. Към
                края на първата година трябва да се създаде навик за почистване
                с четка, като част от времето детето само прави опит и се
                поощрява, а след това родителите почистват качествено зъбите.
              </li>
              <li>
                Първият преглед при детски дентален лекар трябва да е около
                рождения ден на детето. При първата поява на промени по горните
                предни зъби детето трябва спешно да се заведе при детски
                дентален лекар. Кариесът на ранното детство много лесно може да
                се избегне, но много трудно се лекува. Контролът от страна на
                родителите трябва да става чрез повдигане на горната устна на
                детето и наблюдение на горните резци, тъй като те първи се
                засягат. След това процеса напредва към долните резци и
                дъвкателните зъби.
              </li>
            </ul>
            <h3 style={styles.h3}>Ориентировъчни срокове на пробив на временните зъби:</h3>
            <p>
              {" "}
              При смесено съзъбие когато започне пробивът на постоянните зъби
              около шест годишна възраст особено рисков е контакта между първия
              постоянен дъвкателен зъб (шести - той пробива зад всички временни
              и не сменя никой временен) и последният млечен зъб. Последният
              млечен зъб има плоскостна повърхност към постоянния зъб, който е с
              точковидна повърхност. Това от своя страна затруднява почистването
              и повишава риска от развитие на кариес на шести зъб, който е
              дъвкателен център на дъгата.
            </p>
            <img src={Img1} style={styles.img} alt=""/>
            <h3 style={styles.h3}>Ориентировъчни срокове за пробив на постоянни зъби:</h3>
            <p>
              {" "}
              Ако кариесът остана без лечение бързо преминава в пулпит
              (възпаление на кръвоносните съдове и нерви) и след това в
              периодонтит (когато инфекцията е вече в костта). Етапа на пулпит
              много рядко може да се хване поради бързото преминаване на
              процесите един в друг. Периодонтитът при децата най-често се
              проявява с мехурче на венеца до зъба от страната на устните.
              Понякога в подутината има гнойна колекция, която при хранене може
              да се спука и да остане като пъпчица. По този начин зъбът се
              дренира. В някои случаи периодонтитът при децата е съпроводен с
              разпространение на гнойната колекция по хода на меките тъкани,
              получава се оток от съответната страна или т.нар. абсцес.
            </p>
            <img src={Img2} style={styles.img} alt=""/>
            <h3 style={styles.h3}>Лечение:</h3>
            <p>
              {" "}
              След като се премахне разрушената зъбна тъкан, кухината се попълва
              с фотополимер, компомер или глас-йономерен цимент по преценка на
              зъболекаря. При дълбок кариес е необхогимо допълнително поставяне
              на медикамент т.нар. метод на индиректно пулпно покритие.
            </p>
            <p>
              {" "}
              При наличие на <b>пулпит</b> (възпалени кръвоносни съдове и нерви)
              зъба трябва да се умъртви. Поставя се препарат за умъртвяване на
              зъба за няколко дни, като периода зависи от избор на медикамент.
              На второто посещение зъбът се почиства от умъртвените кръвоносни
              съдове и нерви без да се навлиза в каналите на зъба. Поставя се
              медикамент, който има антисептично действие, фосфат цимент и над
              него фотополимер, компомер или глас-йономерен цимент.{" "}
            </p>
            <p>
              {" "}
              При <b>периодонтит</b> (когато инфекцията по пътя на кръвоносните
              съдове и нерви е досигнала костта) взависимост от клиничната
              картина има два подхода:
            </p>
            <ul>
              <li>
                {" "}
                При наличие на подутина до венеца съответният зъб се отваря,
                най-често това са зъби с голяма пломба или голямо разрушение
                вследствие на кариес. Зъбът се почиства поставя се памуче с
                медикамент за няколко дни. Ако има гнойна колекция тя се
                дренира. При необходмост процедурата може да се повтори. По
                преценка на зъболекаря се изписва антибиотик. При стихване на
                симптоматиката зъбът се затваря с пломба - поставя се
                медикамент, който има антисептично действие, фосфат цимент и над
                него фотополимер, компомер или глас-йономерен цимент.{" "}
              </li>
              <li>
                {" "}
                При оток на меките тъкани е задължително да се изпише антибиотик
                съобразен с килограмите на детето. Съответният зъб се отваря
                като най-често това са зъби с голяма пломба или голямо
                разрушение в следствие на кариес. Зъбът се почиства поставя се
                памуче с медикамент за няколко дни. При необходмост процедурата
                се повторя. При стихване на симптоматиката зъбът се затваря с
                пломба - поставя се медикамент, който има антисептично действие,
                фосфат цимент и над него фотополимер, компомер или
                глас-йономерен цимент.{" "}
              </li>
            </ul>
          </article>
        </DefaultWrapper>
      </DefaultContainer>
    </>
  );
}

export default Kids;
