import styled from "styled-components";
import { Link } from 'react-router-dom';
import { MdKeyboardArrowRight, MdArrowForward} from 'react-icons/md'

export const InfoContainer = styled.div`
  color: white;
  background: ${({lightBg}) => (lightBg ? "white" : "var(--c1)")};

  @media screen and (max-width: 768px) {
    padding: 100px 0;
  }
`;

export const InfoWrapper = styled.div`
    display: grid;
    z-index:1;
    min-height: calc(100vh - 100px);
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;
`;

export const InfoRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)};
    }
`;

export const InfoColumn1 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col1;
`;

export const InfoColumn2 = styled.div`
    margin-bottom: 15px;
    padding: 0 15px;
    grid-area: col2;
`;

export const TextWrapper = styled.div`
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;
`;

export const TopLine = styled.p`
    color: var(--c4);
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    letter-spacing: 1.4px;
    text-transform: uppercase;
    margin-bottom:16px;
`;

export const Heading = styled.h1`
    margin-bottom: 14px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: ${({lightText}) => (lightText ? 'var(--c3)' : 'var(--c4)')};

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const Subtitle = styled.p`
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: ${({lightText}) => (lightText ? "var(--c1)" : "var(--c3)")};

`;

export const BtnWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const ImgWrap = styled.div`
    max-width: 550px;
    height: 100%;
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 10px 0;
    padding-right: 0;
    border-radius: 10%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

export const ArrowForward = styled(MdArrowForward)`
    margin-left:8px;
    font-size: 20px;
`
export const ArrowRight = styled(MdKeyboardArrowRight)`
    margin-left:8px;
    font-size: 20px;
`

export const Button = styled(Link)`
    border-radius:50px;
    background: ${({primary}) => primary ? 'var(--c3)' : 'var(--c4)' };
    white-space: nowrap;
    padding: ${({big}) => (big ? "14px 48px" : "12px 30px" )};
    color: ${({light}) => (light ? "var(--c1)" : "va(--c4)" )};
    font-size: ${({fontBig}) => (fontBig ? "20px" : "16px" )};
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content:center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? 'var(--c1)' : 'var(--c2)' )};
    }
`