import styled from "styled-components";
import { Link } from "react-router-dom";

export const FooterContainer = styled.div`
  min-height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--c4);
`;
export const FooterWrapper = styled.div`
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;
export const IconWrapper = styled.div`
  display: inline;
  font-size: 20px;
  vertical-align: middle;
  margin-right: 10px;
`;

export const FooterInfo = styled.div`
  margin: 20px;
`;
export const FooterMapInfo = styled.div`
  flex-basis: 100%;
`;
export const InfoHeader = styled.h2`
  color: var(--c3);
  text-align: center;
`;
export const InfoUl = styled.ul`
  padding: 0;
`;

export const InfoLi = styled.li`
  list-style-type: none;
  color: var(--c2);
`;
export const InfoLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;
export const InfoA = styled.a`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;
export const InfoMaps = styled.a`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom:60px;
`;
export const InfoLineBreak = styled.hr`
  border: 1px solid var(--c2);
`;
export const FooterMapsImg = styled.img`
  width: 95%;
  max-width: 1100px;
  min-height: 300px;
  max-height: 500px;
  object-fit: cover;
  display: block;
  transition: 0.2s ease all;
`;
