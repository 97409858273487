import styled from "styled-components";

export const DefaultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const DefaultWrapper = styled.div`
  max-width: 1300px;
  padding: 24px;
  min-height: 100vh;
`;

export const TopbarContainer = styled.div`
  background: var(--c2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  position: sticky;
  height: 30px;
  top: calc(100vh - 30px);
  z-index: 10;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
  @media screen and (max-width: 700px) {
    height: 40px;
    top: calc(100vh - 40px);
  }
  @media screen and (max-width: 500px) {
    font-size: 0.8rem;
    height: 50px;
    top: calc(100vh - 50px);
  }
`;
export const TopbarWrapper = styled.div`
  display: flex;
  justify-content: right;
  width: 100%;
  height: 100%;
  z-index: 1;
  max-width: 1300px;
  padding: 0 24px;
  gap: 15px;
`;

export const TopbarText = styled.div`
  margin: 0;
  font-weight: bold;
  color: var(--c4);
  align-items: center;
  display: flex;
  
`;
export const IconWrapper = styled.div`
  display: inline;
  font-size: inherit;
  vertical-align: text-top;
  margin-right: 10px;
`;
export const TopbarAHref = styled.a`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;
