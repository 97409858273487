import styled from "styled-components";
import image from "../../images/smile.webp";

export const OverlayContainer = styled.div`
    margin-top: -120px;
  background-image: url(${image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export const OverlayWrapper = styled.div`
max-width:1300px;
  position: relative;
  display: grid;
  z-index: 1;
  height: calc(100vh);
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
`;
export const OverlayRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const OverlayColumn = styled.div`
  margin-bottom: 15px;
  padding: 35px;
`;

export const OverlayH1 = styled.h1`
  color: var(--c1);
  font-size: 48px;
  text-align: left;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const OverlayP = styled.p`
  margin-top: 24px;
  color: var(--c1);
  font-size: 24px;
  text-align: left;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;
