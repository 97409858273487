export const galeryObject = {
  id: "galery",
  primary: true,
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  showButton: true,
  buttonDarkText: true,
  buttonBig: true,
  buttonFontBig: true,
  topLine: " ",
  headLine: "Галерия",
  description:
    "При нас може да получите висококачествена дентална грижа. Редът и чистотата създават спокойна и творческа атмосфера. Разгледайте нашия кабинет.",
  bottonLabel: "Към галерия",
  imgStart: false,
  img: require("../../images/IMG_0218.webp"),
  alt: "smiledent"
};
export const teamObject = {
  id: "team",
  primary: true,
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  showButton: false,
  buttonDarkText: true,
  buttonBig: true,
  buttonFontBig: true,
  topLine: "",
  headLine: "Д-р Моника Воденичарска",
  description:
    " Д-р Моника Воденичарска е родена в гр.София, където завършва НПМГ през 2011г. През 2017 година завършва Факултета по дентална медицина към Медицинския университет – София с почти отличен успех. Интересите й простират във всички области на денталната медицина. Работата с деца е изключително удоволствие за нея. Д-р Воденичарска постоянно повишава своята професионална квалификация и клинични умения с посещение на най-актуалните курсове и семинари. ",
  bottonLabel: "Галерия",
  imgStart: true,
  img: require("../../images/IMG_0179.webp"),
  alt: "smiledent"
};