import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarItem,
  SidebarLink,
} from "./sidebarElements";

const Sidebar = ({isOpen, toggle}) => {
  return (
    <>
      <SidebarContainer isOpen={isOpen} onClick={toggle}>
        <Icon onClick={toggle}>
          <CloseIcon />
        </Icon>
        <SidebarWrapper isOpen={isOpen}>
          <SidebarItem>
            <SidebarLink to="home"
                smooth={true}
                spy={true}
                duration={500}
                exact="true"
                offset={-100} onClick={toggle}>Начало</SidebarLink>
          </SidebarItem>
          <SidebarItem>
            <SidebarLink to="team"
                smooth={true}
                spy={true}
                duration={500}
                exact="true"
                offset={-100} onClick={toggle}>Екип</SidebarLink>
          </SidebarItem>
          <SidebarItem>
            <SidebarLink to="galery"
                smooth={true}
                spy={true}
                duration={500}
                exact="true"
                offset={-100} onClick={toggle}>Галерия</SidebarLink>
          </SidebarItem>
          <SidebarItem>
            <SidebarLink to="services"
                smooth={true}
                spy={true}
                duration={500}
                exact="true"
                offset={-100} onClick={toggle}>Услуги</SidebarLink>
          </SidebarItem>
          <SidebarItem>
            <SidebarLink to="contacts"
                smooth={true}
                spy={true}
                duration={500}
                exact="true"
                offset={-100} onClick={toggle}>Контакти</SidebarLink>
          </SidebarItem>
        </SidebarWrapper>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
